import { createSlice } from "@reduxjs/toolkit";

/*
value holds all necessary info for authentication
{
  accessToken,
  userId,
  accountNo,
}

currently only accessToken is set as userId and accountNo have not been cached (Talk to Ashok and Richard about where to locate these values using MSGraph)
https://docs.microsoft.com/en-us/graph/api/user-get?view=graph-rest-1.0&tabs=http => this may help you
I have a feeling they used an extension schema (see example 4)

*/

export const authenticationStateSlice = createSlice({
  name: "authenticationState",
  initialState: {
    value: {},
  },
  reducers: {
    setAuthenticationState: (state, action) => {
      state.value = action.payload; //eslint-disable-line
    },
  },
});

export const { setAuthenticationState } = authenticationStateSlice.actions;

export default authenticationStateSlice.reducer;
