import { createSlice } from "@reduxjs/toolkit";

/*
Current index of the user when navigating to an invoice from the search page
{
  index,
  filterOptions: consists of {AccountNumber: "", ...}
}

*/

export const paginationStateSlice = createSlice({
  name: "paginationState",
  initialState: {
    value: {},
  },
  reducers: {
    setPaginationState: (state, action) => {
      state.value = action.payload; //eslint-disable-line
    },
  },
});

export const { setPaginationState } = paginationStateSlice.actions;

export default paginationStateSlice.reducer;
