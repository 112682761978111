import React from "react";
import PropTypes from "prop-types";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Paper,
} from "@mui/material";
import ReportRow from "../ReportRow";

export default function ReportTable(props) {
  const { sales, columnNames } = props;

  return (
    <Box>
      <TableContainer component={Paper} sx={{ mt: "2vh" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columnNames.map((columnName) => {
                return (
                  <TableCell key={`${columnName.title}-FUSO-Column`}>
                    {columnName.title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {sales.map((sale) => (
              <ReportRow
                key={`FUSO-row-${sale.index}-${sale.registration}`}
                sale={sale}
                columnNames={columnNames}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

ReportTable.propTypes = {
  sales: PropTypes.arrayOf(PropTypes.object).isRequired, //eslint-disable-line
  columnNames: PropTypes.arrayOf(PropTypes.object).isRequired, //eslint-disable-line
};
