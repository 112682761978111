import PropTypes from "prop-types";

import field from "./fields";

export default function section(props) {
  const { baseSection = null, sectionTitle, fields = [] } = props;
  if (baseSection) {
    return {
      sectionTitle: sectionTitle || baseSection.sectionTitle,
      fields: baseSection.fields.concat(
        fields.map((fieldTitle) => field({ fieldTitle }))
      ),
    };
  }
  return {
    sectionTitle,
    fields: fields.map((fieldTitle) => field({ fieldTitle })),
  };
}

section.propTypes = {
  sectionTitle: PropTypes.string,
  fields: PropTypes.arrayOf,
  baseSection: PropTypes.objectOf,
};
