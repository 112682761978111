import InvoiceDetails from "../sections/InvoiceDetails";
import BreakdownDetails from "../sections/BreakdownDetails";
import NAPSchemeDetails from "../sections/NAPSchemeDetails";
import TowDetails from "../sections/TowDetails";
import section from "../sections";

const NapTowInvoice = {
  invoiceTitle: "NAP Tow Invoice Capture",
  serviceType: "Tow",
  view: false,
  sections: [
    InvoiceDetails,
    BreakdownDetails,
    NAPSchemeDetails,
    section({
      baseSection: TowDetails,
      fields: ["SpecilisedEquiptment"],
    }),
  ],
};

export default NapTowInvoice;
