import InvoiceDetails from "../sections/InvoiceDetails";
import AutoglassJobDetails from "../sections/AutoglassJobDetails";
import section from "../sections";
import VehicleSchemeDetails from "../sections/VehicleSchemeDetails";

const BusinessWiseAutoGlassInvoice = {
  invoiceTitle: "BusinessWise AutoGlass Invoice Capture",
  serviceType: "Autoglass",
  view: false,
  sections: [
    InvoiceDetails,
    AutoglassJobDetails,
    // For some reason this is called Vehicle Membership Details?
    section({
      baseSection: VehicleSchemeDetails,
      sectionTitle: "Vehicle Membership Details",
      fields: ["TelstraDomesticNo"],
    }),
  ],
};

export default BusinessWiseAutoGlassInvoice;
