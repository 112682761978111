import InvoiceDetails from "../sections/InvoiceDetails";
import TowDetails from "../sections/TowDetails";
import section from "../sections";
import BreakdownDetails from "../sections/BreakdownDetails";
import VehicleSchemeDetails from "../sections/VehicleSchemeDetails";

const MembershipPremiumCareTowInvoice = {
  invoiceTitle: "Premium Care Tow Invoice Capture",
  serviceType: "Tow",
  view: false,
  sections: [
    InvoiceDetails,
    section({
      baseSection: BreakdownDetails,
      fields: ["ContractorType", "CallCompletedDate", "JobDocketNo"],
    }),
    VehicleSchemeDetails,
    section({ baseSection: TowDetails, fields: ["KmsClaimed"] }),
  ],
};

export default MembershipPremiumCareTowInvoice;
