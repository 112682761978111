import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import PropTypes from "prop-types";

import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import Container from "@mui/material/Container";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";

import { setAuthenticationState } from "../../features/authenticationState/authenticationState";
import { setSearchState } from "../../features/searchState/searchState";
import { setUserInfoState } from "../../features/userInfoState/userInfoState";
import { setPaginationState } from "../../features/paginationState/paginationState";

import accessPath from "../../utils/accessPath";

import SideDrawer from "../SideDrawer";
import LinkButton from "../LinkButton";

const useStyles = makeStyles({
  button: {
    color: "white",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#3c52b2",
    },
  },
});

export default function ResponsiveAppBar(props) {
  const { showBarElements } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const admin = sessionStorage.getItem("admin") === "true";

  const [anchorElInvoice, setAnchorElInvoice] = React.useState(null);
  const [anchorElQuickLinks, setAnchorElQuickLinks] = React.useState(null);

  const authenticationState = useSelector(
    (state) => state.authenticationState.value
  );

  const settings = admin
    ? ["NAP", "BusinessWise", "Membership"]
    : ["Search", "NAP", "BusinessWise", "Membership"];
  const pages = admin
    ? ["Home", "Admin", "Invoicing", "CSC Help Manual"]
    : ["Home", "Invoicing", "CSC Help Manual"];
  // quick links is admin
  const quickLinks = [
    { title: "Business Motoring", value: "businessMotoring" },
    {
      title: "Reports",
      value: "businessMotoring/BmBwMembershipFusoSalesReport",
    },
  ];

  // Handle logout logic
  const handleLogout = () => {
    instance.logoutPopup();
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("admin");

    dispatch(setAuthenticationState({}));
    dispatch(setSearchState([]));
    dispatch(setUserInfoState({}));
    dispatch(setPaginationState({}));
    navigate("/login");
  };

  const handleOpenInvoiceMenu = (event) => {
    setAnchorElInvoice(event.currentTarget);
  };

  const handleOpenQuickLinksMenu = (event) => {
    setAnchorElQuickLinks(event.currentTarget);
  };

  const handleCloseInvoiceMenu = () => {
    setAnchorElInvoice(null);
  };

  const handleCloseQuickLinksMenu = () => {
    setAnchorElQuickLinks(null);
  };

  const style = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Reset pagination state
  const handleReport = (title) => {
    if (title === "Reports") {
      dispatch(
        setPaginationState({
          index: admin ? -1 : 0,
          filterOptions: admin ? { AccountNumber: "" } : {},
        })
      );
    }
  };

  // Reset pagination state
  const resetPageDash = () => {
    if (accessPath() === "/dashboard") {
      dispatch(
        setPaginationState({
          index: admin ? -1 : 0,
          filterOptions: admin ? { AccountNumber: "" } : {},
        })
      );
    }
  };

  return (
    <>
      <AppBar position="static" component="nav">
        <Container maxWidth="xl">
          <Toolbar
            sx={{
              justifyContent: "space-between",
            }}
            disableGutters
          >
            <Typography
              variant="h4"
              noWrap
              component="div"
              href={accessPath()}
              sx={{
                mr: 2,
                display: "flex",
                letterSpacing: 5,
              }}
            >
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to={accessPath()}
              >
                NeXUS
              </Link>
            </Typography>

            {showBarElements ? (
              <>
                <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
                  {pages.map((page) => {
                    switch (page) {
                      case "Invoicing":
                        return (
                          <Button
                            key={page}
                            onClick={handleOpenInvoiceMenu}
                            className={style.button}
                            sx={{ my: 2 }}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            {page}
                          </Button>
                        );

                      case "Admin":
                        return (
                          <Button
                            key={page}
                            onClick={handleOpenQuickLinksMenu}
                            className={style.button}
                            sx={{ my: 2 }}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            {page}
                          </Button>
                        );

                      case "Home":
                        return (
                          <LinkButton
                            key={page}
                            title={page}
                            className={style.button}
                            link={accessPath()}
                            onClick={() => resetPageDash()}
                            style
                          />
                        );
                      case "CSC Help Manual":
                        return (
                          <LinkButton
                            key={page}
                            title={page}
                            className={style.button}
                            link="/CSCHelpManual"
                            onClick={() =>
                              dispatch(
                                setPaginationState({
                                  index: admin ? -1 : 0,
                                  filterOptions: admin
                                    ? { AccountNumber: "" }
                                    : {},
                                })
                              )
                            }
                            style
                          />
                        );

                      default:
                        return (
                          <Button
                            key={page}
                            className={style.button}
                            sx={{ my: 2 }}
                          >
                            {page}
                          </Button>
                        );
                    }
                  })}

                  <Menu
                    sx={{ mt: "45px" }}
                    anchorEl={anchorElInvoice}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElInvoice)}
                    onClose={handleCloseInvoiceMenu}
                  >
                    {settings.map((setting) => {
                      if (setting === "Search") {
                        return (
                          <Link
                            to="/search"
                            style={{ textDecoration: "none", color: "black" }}
                            key={`${setting}-link`}
                          >
                            <MenuItem key={setting}>
                              <Typography textAlign="center">
                                {setting}
                              </Typography>
                            </MenuItem>
                          </Link>
                        );
                      }

                      return (
                        <Link
                          to={`/invoice/${setting}`}
                          style={{ textDecoration: "none", color: "black" }}
                          key={`${setting}-link`}
                        >
                          <MenuItem
                            key={setting}
                            onClick={() =>
                              dispatch(
                                setPaginationState({
                                  index: admin ? -1 : 0,
                                  filterOptions: admin
                                    ? { AccountNumber: "" }
                                    : {},
                                })
                              )
                            }
                          >
                            <Typography textAlign="center">
                              {setting}
                            </Typography>
                          </MenuItem>
                        </Link>
                      );
                    })}
                  </Menu>

                  <Menu
                    sx={{ mt: "45px" }}
                    anchorEl={anchorElQuickLinks}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElQuickLinks)}
                    onClose={handleCloseQuickLinksMenu}
                  >
                    {quickLinks.map((link) => {
                      return (
                        <Link
                          to={`/${link.value}`}
                          style={{ textDecoration: "none", color: "black" }}
                          key={`${link.value}-link`}
                        >
                          <MenuItem
                            key={link.title}
                            onClick={() => handleReport(link.title)}
                          >
                            <Typography textAlign="center">
                              {link.title}
                            </Typography>
                          </MenuItem>
                        </Link>
                      );
                    })}
                  </Menu>
                </Box>

                <Box
                  sx={{
                    flexGrow: 0,
                    width: "fit-content",
                    alignItems: "center",
                    display: {
                      xs: "none",
                      sm: "flex",
                    },
                  }}
                >
                  <Typography
                    sx={{ display: { xs: "none", md: "flex" } }}
                  >{`Hello ${authenticationState.name}`}</Typography>
                  <Button
                    key="Logout"
                    onClick={() => handleLogout()}
                    className={style.button}
                    sx={{ ml: 3 }}
                  >
                    Logout
                  </Button>
                </Box>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => handleDrawerToggle()}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              </>
            ) : null}
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <SideDrawer
          quickLinks={quickLinks}
          pages={pages}
          settings={settings}
          mobileOpen={mobileOpen}
          handleDrawerToggle={() => handleDrawerToggle()}
          handleLogout={() => handleLogout()}
        />
      </Box>
    </>
  );
}

ResponsiveAppBar.propTypes = {
  showBarElements: PropTypes.bool.isRequired,
};
