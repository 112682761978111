import React from "react";
import PropTypes from "prop-types";
import { InputAdornment, TextField } from "@mui/material";

export default function DashboardCardContent(props) {
  const { title, value, titleCase, Icon } = props;

  return (
    <TextField
      variant="standard"
      fullWidth
      value={value}
      // onMouseOver={(e) => {
      //   e.preventDefault();
      // }}
      // onClick={(e) => {
      //   e.preventDefault();
      // }}
      disabled
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon sx={{ p: 1 }} /> {title}
          </InputAdornment>
        ),
        inputProps: {
          style: {
            fontSize: 20,
            color: "black",
            WebkitTextFillColor: "black",
            textAlign: "right",
            ...(titleCase && { textTransform: "capitalize" }),
          },
          "data-testid": "text-input",
        },
      }}
    />
  );
}

DashboardCardContent.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  Icon: PropTypes.elementType.isRequired,
  titleCase: PropTypes.bool,
};

DashboardCardContent.defaultProps = {
  title: "",
  value: "",
  titleCase: false,
};
