import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes, { bool } from "prop-types";
import { v4 as uuidv4 } from "uuid";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import apiClient from "../../api/apiClient";
import { setPaginationState } from "../../features/paginationState/paginationState";
import { setDropdownOptionsState } from "../../features/dropdownOptionsState/dropdownOptionsState";
import fieldMap from "../../invoices/sections/fields/fieldMap";

export default function RejectionPopup(props) {
  const {
    invoice,
    errors,
    setErrors,
    setLoading,
    invoiceState,
    setInvoiceState,
  } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector(
    (state) => state.paginationState.value.filterOptions
  );
  const selector = useSelector((state) => state.dropdownOptionsState.value);
  const [dropdownOptions, setDropdownOptions] = useState(selector);
  const [rejectReason, setRejectReason] = useState("");
  const [BMComment, setBMComment] = useState("");
  const [error, setError] = useState(false);
  const field = fieldMap.RejectionReason;
  const [options, setOptions] = useState([]);

  // Getting options for dropdown
  useEffect(async () => {
    if (typeof field.options === "function") {
      if (dropdownOptions && dropdownOptions[field.fieldName]) {
        setOptions(dropdownOptions[field.fieldName]);
      } else {
        const opts = await field.options();

        setOptions(opts);
        setDropdownOptions({ ...dropdownOptions, [field.fieldName]: opts });
        dispatch(
          setDropdownOptionsState({
            ...dropdownOptions,
            [field.fieldName]: opts,
          })
        );
      }
    } else {
      setOptions(field.options);
    }
  }, []);

  // Handle if pressed on reject button
  const reject = async () => {
    setLoading(true);
    const response = await apiClient.setInvoiceStatus({
      invoiceId: invoice.invoiceId,
      body: {
        scn: invoice.scn,
        status: "REJECTED",
        RejectReasonCode: rejectReason,
        BMComments: BMComment,
      },
    });
    setInvoiceState(!invoiceState);
    setLoading(false);
    if (response.status === 200) {
      setErrors([
        ...errors,
        {
          title: "Success",
          message: "Invoice Rejected",
          id: uuidv4(),
          severity: "success",
        },
      ]);
    } else {
      setErrors([
        ...errors,
        {
          title: `Failed to change status to REJECTED`,
          id: uuidv4(),
          severity: "error",
        },
      ]);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <Box>
        <Dialog
          key={`${invoice.invoiceId}-dialog`}
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle key={`${invoice.invoiceId}-dialog-reject-title`}>
            Are you sure?
          </DialogTitle>
          <DialogContent>
            <DialogContentText key={`${invoice.invoiceId}-dialog-reject-desc`}>
              This action will reject the invoice. Double check the invoice.
            </DialogContentText>
            <br />
            <Grid key="Rejection-Reason" item>
              <FormControl
                fullWidth
                required
                error={error}
                helpertext="Required"
              >
                <InputLabel id="demo-simple-select-label">
                  Rejection Reason
                </InputLabel>
                <Select
                  id="reject-reason"
                  key="reject-reason"
                  label="Rejection Reason"
                  name="Rejection Reason"
                  value={rejectReason}
                  onChange={(e) => {
                    setError(false);
                    setRejectReason(e.target.value);
                  }}
                >
                  {options.map((option) => {
                    if (typeof option === "string") {
                      return (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      );
                    }
                    return (
                      <MenuItem key={option.title} value={option.value}>
                        {option.title}
                      </MenuItem>
                    );
                  })}
                </Select>
                {error && (
                  <FormHelperText>
                    {field.required ? "Required" : field.errorMessage}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <br />
            <Grid key="BMComments" item>
              <TextField
                required={false}
                multiline
                fullWidth
                label="Comments"
                id="BMComments"
                name="Comments"
                onChange={(e) => {
                  setBMComment(e.target.value);
                }}
                value={BMComment}
                inputProps={{
                  maxLength: 32,
                }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              autoFocus
            >
              Close
            </Button>
            <Button
              onClick={() => {
                reject();
                setOpen(false);
                dispatch(
                  setPaginationState({
                    filterOptions: filters,
                    index: 0,
                  })
                );
              }}
            >
              Reject
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Button
        key={`${invoice.invoiceId}-reject-btn`}
        title="REJECT"
        className="table-button"
        onClick={() => {
          setOpen(true);
        }}
      >
        Reject
      </Button>
    </>
  );
}

RejectionPopup.propTypes = {
  invoice: PropTypes.oneOfType([PropTypes.object]).isRequired, //eslint-disable-line
  errors: PropTypes.arrayOf(PropTypes.object).isRequired, //eslint-disable-line
  setErrors: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  invoiceState: bool.isRequired,
  setInvoiceState: PropTypes.func.isRequired,
};
