import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";

import { Container, Button, Typography } from "@mui/material";

import theme from "../../utils/appTheme";
import apiClient from "../../api/apiClient";
import { setUserInfoState } from "../../features/userInfoState/userInfoState";

import AgentInfo from "../../components/AgentInfo";
import Banner from "../../components/Banner";

import Loading from "../loading";
import NoPermission from "../NoPermissions";

// This is a USER dashboard (doesn't exist on admin interface)
export default function Dashboard() {
  const accessToken = sessionStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfoState.value);
  const userAuth = useSelector((state) => state.authenticationState.value);

  // const accountNo = sessionStorage.getItem("accountNo");
  const [info, setInfo] = useState(userInfo);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // If an admin tries to access this URL/path, it returns a "no permission" page
  if (sessionStorage.getItem("admin") === "true") {
    return <NoPermission />;
  }

  // If there is no access token (person hasn't logged in), it redirects them to the login page
  useEffect(() => {
    if (userAuth.accessToken === undefined) {
      navigate("/login");
    }
  });

  /* Getting the information of the client by performing an API call.
    It also dispatches it into the redux store so there is no need to make another call if info needed.
  */
  // UserID currently hardcoded (need to enquire about this)
  useEffect(async () => {
    if (Object.keys(info).length === 0) {
      setLoading(true);
    }

    let isMounted = true;

    await apiClient
      .getUserInfo({
        accessToken,
        userId: userAuth.accountNumber,
      })
      .then(async (res) => {
        if (res.status === 200) {
          const rjson = await res.json();
          if (isMounted) {
            setInfo(rjson);
            dispatch(setUserInfoState(rjson));
          }
        }
      })
      .catch(() => {});
    setLoading(false);
    return () => {
      isMounted = false;
    };
  }, []);

  // Loading screen if it is busy waiting for the API call
  if (loading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="false" disableGutters>
        <Banner showBarElements />
        <Typography
          align="center"
          variant="h3"
          sx={{ p: "2vh", color: "#3395e3" }}
        >
          {info.name}
        </Typography>
        <Container sx={{ minWidth: "400px" }}>
          <AgentInfo info={info} />
          <Button
            href={`mailto:nexus.admin@mynrma.com.au?subject=Request for Change of Details for ${info.name}`}
            sx={{ mt: 2 }}
          >
            Request Change
          </Button>
        </Container>
      </Container>
    </ThemeProvider>
  );
}
