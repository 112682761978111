import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Container,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useMsal } from "@azure/msal-react";

import getAdminStatus from "../../utils/getAdminStatus";
import theme from "../../utils/appTheme";
import { loginRequest } from "../../authConfig";
import WindowsLogo from "../../assets/LoginLogo";
import { setAuthenticationState } from "../../features/authenticationState/authenticationState";
import { setPaginationState } from "../../features/paginationState/paginationState";

import Banner from "../../components/Banner";
import Copyright from "../../components/Copyright";
import ErrorPopups from "../../components/ErrorPopups";

export default function SignIn() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const authenticationState = useSelector(
    (state) => state.authenticationState.value
  );

  const [inputs, setInputs] = useState([]);

  const { state } = useLocation();

  const [errors, setErrors] = useState(inputs);

  // When a user has already logged in, then the login page should redirect to the corresponding home pages
  useEffect(() => {
    if (authenticationState.accessToken !== undefined) {
      if (sessionStorage.getItem("admin") === "true") {
        navigate("/search");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);

  useEffect(() => {
    if (state !== undefined && state !== null) {
      setInputs([state]);
      setErrors([state]);
    } else {
      setInputs([]);
      setErrors([]);
    }
    window.history.replaceState(undefined, document.title);
  }, []);

  /* This sends a login request to azure functions, sets access tokens, ids, and admin permissions, and dispatches to the redux store
    It then redirects the page to a corresponding home page
  */
  const handleLogin = (msalInstance) => {
    msalInstance
      .loginPopup(loginRequest)
      .then(async (e) => {
        const admin = await getAdminStatus(e.accessToken);
        sessionStorage.setItem("accessToken", e.accessToken);
        sessionStorage.setItem("id", e.idToken);
        sessionStorage.setItem("admin", admin);
        dispatch(
          setPaginationState({
            index: admin ? -1 : 0,
            filterOptions: admin ? { AccountNumber: "" } : {},
          })
        );
        dispatch(
          setAuthenticationState({
            accessToken: e.accessToken,
            id: e.idToken,
            name: e.account.name,
          })
        );
        if (sessionStorage.getItem("admin") === "true") {
          navigate("/search");
        } else {
          navigate("/dashboard");
        }
      })
      .catch(() => {
        setErrors([
          {
            title: "Error",
            message: "Please login with a valid account",
            id: "noperm",
          },
        ]);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="false" disableGutters>
        <Banner showBarElements={false} />
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ErrorPopups errors={errors} setErrors={setErrors} />
            <Avatar sx={{ m: 1 }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in With Azure
            </Typography>

            <Box sx={{ mt: 1 }}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleLogin(instance)}
                sx={{ mt: 3, mb: 2, p: 1, width: "10vw", alignItems: "center" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    p: 0,
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Typography>Sign in</Typography>
                  <Box sx={{ pt: "5%", width: "15%" }}>
                    <WindowsLogo />
                  </Box>
                </Box>
              </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </Container>
    </ThemeProvider>
  );
}
