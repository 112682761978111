import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";

import {
  Dashboard,
  SearchPage,
  InvoicePage,
  BmBwMembershipFusoSalesReport,
  CSCHelpManual,
} from "../pages";

import {
  NapBatteryInvoice,
  NapTowInvoice,
  NapOtherInvoice,
  BusinessWiseAutoGlassInvoice,
  BusinessWiseBatteryInvoice,
  BusinessWiseCarElectricsInvoice,
  MembershipPremiumCareTowInvoice,
  BusinessWiseTowInvoice,
} from "../invoices";

// This contains all the possible routes (apart from login)
const routes = async () => {
  return [
    <Route key="/dashboard" exact path="/dashboard" element={<PrivateRoute />}>
      <Route exact path="/dashboard" element={<Dashboard />} />
    </Route>,

    <Route key="/search" exact path="/search" element={<PrivateRoute />}>
      <Route exact path="/search" element={<SearchPage />} />
    </Route>,

    <Route
      key="/businessMotoring/BmBwMembershipFusoSalesReport"
      exact
      path="/businessMotoring/BmBwMembershipFusoSalesReport"
      element={<PrivateRoute />}
    >
      <Route
        exact
        path="/businessMotoring/BmBwMembershipFusoSalesReport"
        element={<BmBwMembershipFusoSalesReport />}
      />
    </Route>,

    <Route
      key="/businessMotoring"
      exact
      path="/businessMotoring"
      element={<PrivateRoute />}
    >
      <Route exact path="/businessMotoring" element={<SearchPage />} />
    </Route>,

    <Route
      key="invoice/NAP"
      exact
      path="/invoice/NAP"
      element={<PrivateRoute />}
    >
      <Route
        exact
        path="/invoice/NAP"
        element={
          <InvoicePage
            invoiceType="NAP"
            invoices={[NapBatteryInvoice, NapTowInvoice, NapOtherInvoice]}
          />
        }
      />
    </Route>,

    <Route
      key="/invoice/view/:invoiceTypeParam/:serviceType/:invoiceId/:invoiceStatus"
      exact
      path="/invoice/view/:invoiceTypeParam/:serviceType/:invoiceId/:invoiceStatus"
      element={<PrivateRoute />}
    >
      <Route
        exact
        path="/invoice/view/:invoiceTypeParam/:serviceType/:invoiceId/:invoiceStatus"
        element={<InvoicePage invoiceType="view" />}
      />
    </Route>,

    <Route
      key="invoice/BusinessWise"
      exact
      path="/invoice/BusinessWise"
      element={<PrivateRoute />}
    >
      <Route
        exact
        path="/invoice/BusinessWise"
        element={
          <InvoicePage
            invoiceType="BusinessWise"
            invoices={[
              BusinessWiseAutoGlassInvoice,
              BusinessWiseBatteryInvoice,
              BusinessWiseCarElectricsInvoice,
              BusinessWiseTowInvoice,
            ]}
          />
        }
      />
    </Route>,

    <Route
      key="invoice/Membership"
      exact
      path="/invoice/Membership"
      element={<PrivateRoute />}
    >
      <Route
        exact
        path="/invoice/Membership"
        element={
          <InvoicePage
            invoiceType="Membership"
            invoices={[MembershipPremiumCareTowInvoice]}
          />
        }
      />
    </Route>,

    <Route
      key="/CSCHelpManual"
      exact
      path="/CSCHelpManual"
      element={<PrivateRoute />}
    >
      <Route exact path="/CSCHelpManual" element={<CSCHelpManual />} />
    </Route>,
  ];
};

export default routes;
