import membershipPremiumCareTow from "./membershipPremiumCareTow";
import businessWiseBattery from "./businesssWiseBattery";
import businessWiseAutoglass from "./businessWiseAutoglass";
import businessWiseCarElectrics from "./businessWiseCarElectrics";
import businessWiseTow from "./businessWiseTow";
import napBattery from "./napBattery";
import napOther from "./napOther";
import napTow from "./napTow";

const invoiceMap = {
  "PC-TOW": membershipPremiumCareTow,
  "BW-BATTERY": businessWiseBattery,
  "BW-AUTOGLASS": businessWiseAutoglass,
  "BW-TOW": businessWiseTow,
  "BW-CARELEC": businessWiseCarElectrics,
  "NAP-BATTERY": napBattery,
  "NAP-TOW": napTow,
  "NAP-OTHER": napOther,
};

export default invoiceMap;
