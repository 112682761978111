import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  Container,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import apiClient from "../../api/apiClient";
import theme from "../../utils/appTheme";
import invoiceTypeCode from "../../utils/invoiceTypeCode";

import Banner from "../../components/Banner";
import InvoiceForm from "../../components/InvoiceForm";
import invoiceMap from "../../invoices/invoiceMap";

import Loading from "../loading";
import NoPermission from "../NoPermissions";
import NotFound from "../NotFound";

export default function InvoicePage(props) {
  const { invoices, invoiceType } = props;
  const navigate = useNavigate();
  const admin = sessionStorage.getItem("admin") === "true";

  const [form, setForm] = useState({});

  const [currentInvoiceType, setCurrentInvoiceType] = useState("");
  const [currentServiceType, setCurrentServiceType] = useState("");

  const [readOnly, setReadOnly] = useState(false);
  const [formData, setFormData] = useState({});
  const [costTableRows, setCostTableRows] = useState([]);
  const [awaiting, setAwaiting] = useState(false);
  const [rejectedInvoice, setRejectedInvoice] = useState(false);
  const [errors, setErrors] = useState([]);
  const [originalStatus, setOriginalStatus] = useState("");
  const [badStatus, setBadStatus] = useState(false);
  const [noPerm, setNoPerm] = useState(false);

  const userInfo = useSelector((state) => state.userInfoState.value);
  const [info] = useState(userInfo);
  const authState = useSelector((state) => state.authenticationState.value);

  // If no access token, they are redirected to login page
  useEffect(() => {
    if (authState.accessToken === undefined) {
      navigate("/login");
    }
  });

  // When the type of invoice selected has changed (e.g. Tow, Autoglass, etc.)
  const handleChange = (event) => {
    const temp = event.target.value;
    temp.view = invoiceType === "view";

    setForm(temp);
    setCurrentServiceType(temp.serviceType);
    setFormData({});
    setCostTableRows([]);
  };

  // Getting information about the invoice through the parameters of the path
  const { invoiceId, serviceType, invoiceTypeParam, invoiceStatus } =
    useParams();
  // There are inconsistent information between the local information and database information
  if (currentInvoiceType !== invoiceType) {
    setCurrentInvoiceType(invoiceType);
    setForm(null);
  }

  // Getting the invoice via API call, and setting information from the call
  useEffect(async () => {
    if (invoiceType === "view") {
      setAwaiting(true);
      setReadOnly(invoiceStatus !== "SAVED" && invoiceStatus !== "EDIT");
      setCurrentInvoiceType(invoiceTypeParam);
      setCurrentServiceType(serviceType);

      const response = await apiClient.getInvoice({
        token: authState.accessToken,
        invoiceId,
        queries: {
          serviceType,
        },
      });

      if (response.status === 200) {
        const responseJson = await response.json();

        const invoiceTemplate = _.cloneDeep(
          invoiceMap[`${invoiceTypeParam}-${serviceType}`]
        );
        if (
          invoiceStatus !== "SAVED" &&
          invoiceStatus !== "EDIT" &&
          invoiceTemplate.sections[0].fields.length === 2
        ) {
          invoiceTemplate.sections[0].fields.push({
            fieldTitle: "Invoice Status",
            fieldName: "InvoiceStatusCode",
            type: "TextField",
          });

          invoiceTemplate.sections[0].fields.push({
            fieldTitle: "Status Date",
            fieldName: "StatusChangeDate",
            type: "Date",
          });
        }

        if (invoiceStatus === "REJECTED") {
          setRejectedInvoice(true);
        }

        invoiceTemplate.view = true;

        setFormData({
          ...responseJson.formData,
          ...Object.entries(responseJson.formData)
            .filter(([key, val]) => {
              return val && key.toLowerCase().includes("date");
            })
            .reduce(
              (prev, [key, val]) => ({
                ...prev,
                [key]: new Date(
                  new Date(val) - new Date().getTimezoneOffset() * 60 * 1000
                ),
              }),
              {}
            ),
          scn: responseJson.scn,
          InvoiceStatusCode: invoiceStatus,
        });
        setCostTableRows(responseJson.costTable);
        setForm(invoiceTemplate);
        setOriginalStatus(responseJson.formData.InvoiceStatusCode);

        if (
          responseJson.formData.InvoiceStatusCode === "PENDING PAYMENT" &&
          invoiceStatus === "EDIT"
        ) {
          setBadStatus(true);
        }

        if (
          responseJson.formData.InvoiceStatusCode !== invoiceStatus &&
          invoiceStatus !== "EDIT"
        ) {
          setBadStatus(true);
        }

        if (
          responseJson.formData.InvoiceStatusCode === "REJECTED" &&
          invoiceStatus === "EDIT" &&
          admin
        ) {
          setNoPerm(true);
        }
        setAwaiting(false);
      }
    }
  }, [invoices, invoiceStatus]);

  // If there is no existing invoice
  if (badStatus) {
    return <NotFound />;
  }

  // If user cannot view the invoice
  if (noPerm) {
    return <NoPermission />;
  }

  // For when app is busy waiting for API call
  if (awaiting) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="false" disableGutters>
        <Banner showBarElements />
        <Typography
          align="center"
          variant="h3"
          sx={{ p: "2vh", color: "#3395e3" }}
        >
          {info.name}
        </Typography>
        {invoices.length !== 0 ? (
          <Typography variant="h4" sx={{ ml: "9vw" }}>
            {`${currentInvoiceType} Invoices`}
          </Typography>
        ) : null}

        {invoices.length !== 0 ? (
          <Box minWidth="200px" sx={{ width: "20vw", ml: "9vw", mt: "1vh" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Invoice
              </InputLabel>
              <Select
                label="Select Invoice"
                value={form || ""}
                onChange={handleChange}
              >
                {invoices.map((invoice) => {
                  return (
                    <MenuItem key={invoice.invoiceTitle} value={invoice}>
                      {invoice.serviceType}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        ) : null}

        {form === null ? null : (
          <InvoiceForm
            readOnly={readOnly}
            setReadOnly={setReadOnly}
            invoiceId={invoiceId}
            form={form}
            formData={formData}
            rejectedInvoice={rejectedInvoice}
            setRejectedInvoice={setRejectedInvoice}
            setFormData={setFormData}
            costTableRows={costTableRows}
            setCostTableRows={setCostTableRows}
            invoiceType={
              currentInvoiceType !== "view"
                ? invoiceTypeCode(currentInvoiceType)
                : invoiceTypeParam
            }
            originalStatus={originalStatus}
            serviceType={currentServiceType}
            errors={errors}
            setErrors={setErrors}
            admin={admin}
          />
        )}
      </Container>
    </ThemeProvider>
  );
}

InvoicePage.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line
  invoiceType: PropTypes.string.isRequired,
};

InvoicePage.defaultProps = {
  invoices: [],
};
