const searchFilterFields = [
  {
    fieldName: "InvoiceType",
    fieldTitle: "Invoice Type",
    type: "Dropdown",
    default: "All",
    options: [
      "All",
      { title: "BusinessWise", value: "BW" },
      { title: "Membership Premium Care", value: "PC" },
      { title: "NAP", value: "NAP" },
    ],
  },
  {
    fieldName: "ServiceType",
    fieldTitle: "Service Type",
    type: "Dropdown",
    default: "All",
    options: [
      "All",
      { title: "Autoglass", value: "AUTOGLASS" },
      { title: "Battery", value: "BATTERY" },
      { title: "Car Electrics", value: "CARELEC" },
      { title: "Tow", value: "TOW" },
      { title: "Other", value: "OTHER" },
    ],
  },
  {
    fieldName: "InvoiceStatus",
    fieldTitle: "Status",
    type: "Dropdown",
    default: "All",
    options: [
      "All",
      "Saved",
      "Submitted",
      "Approved",
      "Rejected",
      "Pending Payment",
    ],
  },
  {
    fieldName: "FromDate",
    fieldTitle: "From Date",
    type: "Date",
  },
  {
    fieldName: "ToDate",
    fieldTitle: "To Date",
    type: "Date",
  },
  {
    fieldName: "InvoiceNumber",
    fieldTitle: "Invoice No.",
    type: "TextField",
    characterLimit: 10,
  },
  {
    fieldName: "MembershipNumber",
    fieldTitle: "Membership No.",
    type: "TextField",
    characterLimit: 10,
  },
  {
    fieldName: "RegistrationNumber",
    fieldTitle: "Registration No.",
    type: "TextField",
    characterLimit: 8,
  },
  {
    fieldName: "CadJobNumber",
    fieldTitle: "CAD Job No.",
    type: "TextField",
    characterLimit: 14,
  },
];

export default searchFilterFields;
