import React from "react";
import Typography from "@mui/material/Typography";

export default function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      &quot;NRMA Motoring + Services&quot; is a trading name of NRMA Limited. ©
      2006 NRMA Limited.
    </Typography>
  );
}
