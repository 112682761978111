const accessPath = () => {
  let url;
  if (!sessionStorage.getItem("accessToken")) {
    url = "/login";
  } else if (sessionStorage.getItem("admin") === "true") {
    url = "/search";
  } else {
    url = "/dashboard";
  }
  return url;
};

export default accessPath;
