export const msalConfig = {
  auth: {
    clientId: "a576f06a-5b06-4bc0-9d71-ae2ac62fcbd7",
    authority:
      "https://login.microsoftonline.com/03e44363-8cf9-4584-ad0d-bf10f76fb000", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read", "User.Read.All"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  transitiveMemberOf: "https://graph.microsoft.com/v1.0/me/transitiveMemberOf",
  userInfo:
    "https://graph.microsoft.com/v1.0/me?$select=onPremisesExtensionAttributes",
  admin:
    "https://graph.microsoft.com/v1.0/me/transitiveMemberOf?$filter=(id eq 'ef5b870d-b8de-4e11-84d8-f0aa924d2cfe')",
  readOnlyAdmin:
    "https://graph.microsoft.com/v1.0/me/transitiveMemberOf?$filter=(id eq 'f9ea3e99-ab6e-463a-be20-dc3ae87a9d42')",
};
