import { graphConfig } from "../authConfig";
import getAdminStatus from "./getAdminStatus";

// Getting the user permissions
const getUserGroups = async (authenticationState) => {
  const accessLogToken = authenticationState.accessToken;
  if (accessLogToken) {
    sessionStorage.setItem("accessToken", accessLogToken);
    sessionStorage.setItem("admin", await getAdminStatus(accessLogToken));
    sessionStorage.setItem("id", authenticationState.id);
  }
  const headers = new Headers();
  const bearer = `Bearer ${accessLogToken}`;

  headers.append("Authorization", bearer);
  headers.append("ConsistencyLevel", "eventual");

  const options = {
    method: "GET",
    headers,
  };

  return fetch(graphConfig.transitiveMemberOf, options).then((response) => {
    if (response.status === 200) {
      return response.json();
    }

    throw new Error("unable to get user groups");
  });
};

const getUserInfo = async () => {
  const accessToken = sessionStorage.getItem("accessToken");
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);
  headers.append("ConsistencyLevel", "eventual");
  const options = {
    method: "GET",
    headers,
  };
  return fetch(graphConfig.userInfo, options).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw new Error("unable to get userInfo");
  });
};

const validRoles = [
  "App-Nexus-PRD-Agent",
  "App-Nexus-PRD-BusinessMotoring",
  "App-Nexus-PRD-Branch",
  "SG-LIC-DESKLESSPACK",
  "OIM-App-Nexus-Administrators-Prod",
  "OIM-App-Nexus-Administrators-PROD",
];

// Checks if user is logged in (called in PrivateRoute)
const isLoggedin = async (
  dispatch,
  authenticationState,
  setAuthenticationState
) => {
  try {
    const response = await getUserGroups(authenticationState);

    // console.log(userInfo.onPremisesExtensionAttributes.extensionAttribute10);
    // value for accountNo used to be stored here. Talk to Ashok and Richard about where it is currently stored
    // also ask about where userID is stored too
    // need to cache accountNo and userId here so that we can pass that to backend using redux/useSelector

    // currently only accessToken is set as userId and accountNo have not been cached (Talk to Ashok and Richard about where to locate these values using MSGraph)
    // https://docs.microsoft.com/en-us/graph/api/user-get?view=graph-rest-1.0&tabs=http => this may help you
    // I have a feeling they used an extension schema (see example 4)
    const userInfo = await getUserInfo();
    dispatch(
      setAuthenticationState({
        ...authenticationState,
        accountNo: userInfo.onPremisesExtensionAttributes.extensionAttribute10,
      })
    );

    //  eslint-disable-next-line
    for await (const value of response.value) {
      if (validRoles.includes(value.displayName)) {
        return true;
      }
    }

    // should return false when ready for deployment. Currently set to true for testing
    dispatch(setAuthenticationState({}));
    return false;
  } catch {
    dispatch(setAuthenticationState({}));
    return false;
  }
};

export default isLoggedin;
