import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Box, Button, CircularProgress } from "@mui/material";

import { setPaginationState } from "../../features/paginationState/paginationState";
import searchFilterFields from "../../invoices/search";
import FormTextField from "../FormTextField";
import FormDatePicker from "../FormDatePicker";
import FormDropdown from "../FormDropdown";
import apiClient from "../../api/apiClient";

export default function SearchFilter(props) {
  const { filterOptions, setFilterOptions, admin, loading } = props;
  const [newFilterOptions, setNewFilterOptions] = useState(filterOptions);
  const pageIndex = useSelector((state) => state.paginationState.value.index);
  const dispatch = useDispatch();

  const onSearch = () => {
    setFilterOptions(newFilterOptions);
    dispatch(
      setPaginationState({
        index: 0,
        filterOptions: newFilterOptions,
      })
    );
  };

  const onClear = () => {
    const newFilter = {};
    Object.keys(newFilterOptions).forEach((field) => {
      if (field !== "ToDate" || field !== "FromDate") {
        // Skip dates
        newFilter[field] = "";
      }
    });
    setNewFilterOptions(newFilter);
    dispatch(
      setPaginationState({
        index: pageIndex,
        filterOptions: newFilter,
      })
    );
  };

  return (
    <Box sx={{ p: 0, mt: 3 }}>
      <Grid container spacing={2}>
        {searchFilterFields.map((field) => {
          switch (field.type) {
            case "TextField":
              return (
                <FormTextField
                  setFormData={setNewFilterOptions}
                  key={`text-${field.fieldName}`}
                  field={field}
                  formData={newFilterOptions}
                  readOnly={false}
                  size={3}
                  height="small"
                />
              );
            case "Date":
              return (
                <FormDatePicker
                  setFormData={setNewFilterOptions}
                  key={`date-${field.fieldName}`}
                  field={field}
                  formData={newFilterOptions}
                  readOnly={false}
                  size={3}
                  height="small"
                />
              );
            case "Dropdown":
              return (
                <FormDropdown
                  setFormData={setNewFilterOptions}
                  key={`drop-${field.fieldName}`}
                  field={field}
                  formData={newFilterOptions}
                  readOnly={false}
                  size={2}
                  height="small"
                />
              );
            default:
              return null;
          }
        })}
        {admin && (
          <FormDropdown
            setFormData={setNewFilterOptions}
            field={{
              fieldName: "AccountNumber",
              fieldTitle: "CSC",
              default: "ALL",
              options: async () => {
                let opts = ["ALL"];
                await apiClient.getUsers().then(async (res) => {
                  if (res.status === 200) {
                    const rjson = await res.json();
                    opts = [
                      "ALL",
                      ...rjson.users.reduce((users, curr) => {
                        return [
                          ...users,
                          {
                            title: `${curr.name} (${curr.postCode})`,
                            value: curr.accountNumber,
                          },
                        ];
                      }, []),
                    ];
                  }
                });
                return opts;
              },
            }}
            formData={newFilterOptions}
            readOnly={false}
            size={12}
          />
        )}
      </Grid>

      <Box
        sx={{
          mt: "2vh",
          display: "Flex",
          width: "20vw",
          minWidth: "225px",
          maxWidth: "400px",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            onClear();
          }}
        >
          Clear
        </Button>

        <Button variant="contained" onClick={onSearch}>
          Search
        </Button>
        <CircularProgress
          sx={{
            visibility: loading ? "visible" : "hidden",
          }}
        />
      </Box>
    </Box>
  );
}

SearchFilter.propTypes = {
  filterOptions: PropTypes.object.isRequired, // eslint-disable-line
  setFilterOptions: PropTypes.func.isRequired,
  admin: PropTypes.bool,
  loading: PropTypes.bool,
};

SearchFilter.defaultProps = {
  admin: false,
  loading: false,
};
