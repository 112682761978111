const API_URL =
  "https://sls-ause-dev-nexus-rebuild-bd.azurewebsites.net/api/v1";

// The API call
const API = {
  request: async ({
    path,
    body,
    header = {},
    queries = {},
    method = "GET",
  }) => {
    let endpoint = `${API_URL}/${path}?`;
    if (method !== "POST" || method !== "PUT") {
      endpoint += Object.entries(queries)
        .map(([key, val]) => `${key}=${val}`)
        .join("&");
    }

    const payload = {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        ...header,
      },
    };

    if (method !== "GET") {
      payload.body = JSON.stringify(body);
    }

    const response = await fetch(endpoint, payload);
    return response;
  },
};

export default API;
