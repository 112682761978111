import React from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell } from "@mui/material";

export default function ReportRow(props) {
  const { sale, columnNames } = props;

  return (
    <TableRow
      key={`FUSO-sale-${sale.index}`}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      {columnNames.map((column) => {
        return (
          <TableCell key={`FUSO-row-cell-${column.value}-${sale.index}`}>
            {column.value.includes("Date")
              ? new Date(
                  new Date(sale[column.value]) -
                    new Date().getTimezoneOffset() * 60 * 1000
                ).toLocaleDateString("en-AU")
              : sale[column.value]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

ReportRow.propTypes = {
  sale: PropTypes.oneOfType([PropTypes.object]).isRequired,
  columnNames: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line
};
