import React from "react";
import { useNavigate } from "react-router-dom";

import {
  CssBaseline,
  Box,
  Container,
  Button,
  Typography,
  ThemeProvider,
} from "@mui/material";
import DesignServicesIcon from "@mui/icons-material/DesignServices";

import theme from "../../utils/appTheme";
import accessPath from "../../utils/accessPath";

import Banner from "../../components/Banner";
import Copyright from "../../components/Copyright";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="false" disableGutters>
        <Banner showBarElements={false} />
        <Container component="main" maxWidth="false">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              marginBottom: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "60vh",
              justifyContent: "space-between",
            }}
          >
            <DesignServicesIcon style={{ fontSize: 200 }} color="warning" />
            <br />
            <Typography variant="h3">
              Sorry, this content does not exist
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate(accessPath());
              }}
              size="large"
            >
              Back to site
            </Button>
            <Typography variant="h6">Please check the URL</Typography>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Box>
        </Container>
      </Container>
    </ThemeProvider>
  );
}
