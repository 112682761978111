import { createSlice } from "@reduxjs/toolkit";

/*
value is an array of searchRow objects returned by /getInvoices endpoint
[{
  invoiceId
  invoiceType
  invoiceTypeName
  serviceType
  invoiceDate
  invoiceNo
  cadJobNo
  registration
  breakdownDate
  breakdownLocation
  totalValue
  statusChanged
  status
  membershipNo
}]

*/

export const searchStateSlice = createSlice({
  name: "searchState",
  initialState: {
    value: [],
  },
  reducers: {
    setSearchState: (state, action) => {
      state.value = action.payload; //eslint-disable-line
    },
  },
});

export const { setSearchState } = searchStateSlice.actions;

export default searchStateSlice.reducer;
