import { createSlice } from "@reduxjs/toolkit";

/*
value is the json returned by /schemes endpoint
[
  {title, value}
]

*/

export const dropdownOptionsStateSlice = createSlice({
  name: "dropdownOptionsState",
  initialState: {},
  reducers: {
    setDropdownOptionsState: (state, action) => {
      state.value = action.payload; //eslint-disable-line
    },
  },
});

export const { setDropdownOptionsState } = dropdownOptionsStateSlice.actions;

export default dropdownOptionsStateSlice.reducer;
