import apiClient from "../../../api/apiClient";

const fieldMap = {
  InvoiceDate: {
    fieldTitle: "Invoice Date",
    fieldName: "InvoiceDate",
    required: true,
    type: "Date",
  },
  Date: {
    fieldTitle: "Date of Job",
    fieldName: "BreakdownDate",
    required: true,
    type: "Date",
  },
  InvoiceNo: {
    fieldName: "AgentInvoiceReference",
    fieldTitle: "Invoice No.",
    required: true,
    type: "TextField",
    characterLimit: 10,
  },
  BreakdownDate: {
    fieldName: "BreakdownDate",
    fieldTitle: "Breakdown Date",
    required: true,
    type: "Date",
  },
  BreakdownLocation: {
    fieldName: "BreakdownLoc",
    fieldTitle: "Breakdown Location",
    required: true,
    type: "TextField",
    characterLimit: 50,
    errorMessage: "Enter the suburb the vehicle was broken down in",
  },
  Location: {
    fieldName: "BreakdownLoc",
    fieldTitle: "Location",
    required: true,
    type: "TextField",
    characterLimit: 50,
  },
  CallCompletedDate: {
    fieldName: "CallCompletedDate",
    fieldTitle: "Call Completed Date",
    required: false,
    type: "Date",
  },
  CADJobNo: {
    fieldName: "CadJobNumber",
    fieldTitle: "CAD Job No.",
    required: true,
    type: "TextField",
    characterLimit: 14,
    pattern: "^[1-2][0-9]{3}[0-1][0-9][0-3][0-9]{7}$",
    errorMessage:
      "CAD number is the date the job was created + six numbers. I.E. YYYY/MM/DD/six numbers",
  },
  BreakdownCode: {
    fieldName: "BreakdownCode",
    fieldTitle: "Breakdown Code",
    required: true,
    type: "TextField",
    characterLimit: 4,
  },
  TowedFrom: {
    fieldName: "TowedFrom",
    fieldTitle: "Towed From",
    required: true,
    type: "TextField",
    characterLimit: 80,
  },
  TowedTo: {
    fieldName: "TowedTo",
    fieldTitle: "Towed To",
    required: true,
    type: "TextField",
    characterLimit: 80,
  },
  KmsTowed: {
    fieldName: "TowedKm",
    fieldTitle: "Kilometers Towed",
    required: true,
    type: "TextField",
    characterLimit: 10,
    endAdornment: "Kms",
  },
  KmsClaimed: {
    fieldName: "ClaimedKm",
    fieldTitle: "Kilometers Claimed",
    required: true,
    type: "TextField",
    characterLimit: 12,
    endAdornment: "Kms",
  },
  SpecilisedEquiptment: {
    fieldName: "SpecializedEquiptmentReq",
    fieldTitle: "Specialised towing equiptment used",
    required: true,
    default: 0,
    type: "Dropdown",
    options: [
      { title: "No", value: 0 },
      { title: "Yes", value: 1 },
    ],
  },
  MembershipNo: {
    fieldName: "MembershipNumber",
    fieldTitle: "Membership No.",
    required: true,
    type: "TextField",
    characterLimit: 9,
    pattern: "^[0-9]{3,9}$",
    errorMessage: "The membership number must be 3 to 9 digits",
  },
  RegistrationNo: {
    fieldName: "RegistrationNumber",
    fieldTitle: "Registration No.",
    required: true,
    type: "TextField",
    characterLimit: 8,
  },
  VehicleMake: {
    fieldName: "VehicleMake",
    fieldTitle: "Vehicle Make",
    required: true,
    type: "TextField",
    characterLimit: 20,
  },
  VehicleModel: {
    fieldName: "VehicleModel",
    fieldTitle: "Vehicle Model",
    required: true,
    type: "TextField",
    characterLimit: 20,
  },
  TelstraDomesticNo: {
    fieldName: "TelstraDomesticNumber",
    fieldTitle: "Telstra Domestic No.",
    required: true,
    type: "TextField",
    characterLimit: 50,
  },
  OdometerReading: {
    fieldName: "Odometer",
    fieldTitle: "Odometer Reading",
    required: true,
    type: "TextField",
    characterLimit: 10,
    endAdornment: "Kms",
  },
  GlassJobNo: {
    fieldName: "JobNumber",
    fieldTitle: "Glass Job No.",
    required: true,
    type: "TextField",
    characterLimit: 14,
  },
  JobNo: {
    fieldName: "JobNumber",
    fieldTitle: "Job No.",
    required: true,
    type: "TextField",
    characterLimit: 14,
  },
  JobType: {
    fieldName: "JobType",
    fieldTitle: "Job Type",
    required: true,
    type: "TextField",
    characterLimit: 20,
  },
  ContractorType: {
    fieldName: "JobRegion",
    fieldTitle: "Contractor Type",
    required: true,
    type: "Dropdown",
    default: "COUNTRY",
    options: [
      { title: "Country", value: "CSC" },
      { title: "Metro", value: "METRO" },
    ],
  },
  JobDocketNo: {
    fieldName: "JobDocketNumber",
    fieldTitle: "Job Docket No.",
    required: true,
    type: "TextField",
    characterLimit: 7,
  },
  NAPScheme: {
    fieldName: "Scheme",
    fieldTitle: "NAP Scheme",
    required: true,
    type: "Dropdown",
    default: "-- Select --",
    options: async () => {
      const responce = await apiClient.getNAPSchemes({});
      const rjson = await responce.json();
      const NAPSchemes = [
        "-- Select --",
        ...Object.entries(rjson.schemes).map(([key, val]) => ({
          title: `${key} (${val})`,
          value: key,
        })),
      ];
      return NAPSchemes;
    },
  },
  AuthorisationNo: {
    fieldName: "AuthCode",
    fieldTitle: "Authorisation No.",
    required: false,
    type: "TextField",
    characterLimit: 30,
  },
  VINNo: {
    fieldName: "VIN",
    fieldTitle: "VIN No.",
    required: true,
    type: "TextField",
    pattern: "^[^O,I,Q,o,i,q]{17}$",
    characterLimit: 17,
    errorMessage:
      "The VIN must be 17 digits and cannot contain O (oscar), I (India), Q (quebec)",
  },
  RejectionReason: {
    fieldName: "RejectReasonCode",
    fieldTitle: "Rejection Reason",
    required: false,
    type: "Dropdown",
    errorMessage: "Requried For Rejection",
    options: async () => {
      const responce = await apiClient.getRejectReasons({});
      const rjson = await responce.json();
      return [
        ...Object.entries(rjson.reasons).map(([key, val]) => ({
          title: val,
          value: key,
        })),
      ];
    },
  },
  BMComments: {
    fieldName: "BMComments",
    multiline: true,
    fieldTitle: "Comments",
    required: false,
    type: "TextField",
    characterLimit: 32,
  },
  FromDate: {
    fieldName: "FromDate",
    fieldTitle: "From Date",
    type: "Date",
  },
  ToDate: {
    fieldName: "ToDate",
    fieldTitle: "To Date",
    type: "Date",
  },
  Dealer: {
    fieldName: "Dealer",
    fieldTitle: "Dealer",
    default: "All",
    required: false,
    type: "Dropdown",
    options: [
      "All",
      { title: "Agus Bintoro", value: "abintoro" },
      { title: "Atchinson Truck Repairs Pty Ltd", value: "mittn212" },
      { title: "HartWigs Trucks Pty Ltd", value: "mittn794" },
      { title: "J T Fossey (Sales) Pty Ltd", value: "mittn502" },
      { title: "K & J Trucks Coffs Harbour Pty Ltd", value: "mittn711" },
      { title: "Newcastle Mitsubishi Trucks", value: "mittn210" },
      { title: "Rantselle Pty Ltd", value: "mittv149" },
      { title: "Swanes Truck Centre", value: "mittn786" },
      { title: "TBA", value: "mittn280" },
      { title: "The Truck Centre", value: "mittn592" },
      { title: "Tri City Trucks (NSW) Pty Ltd", value: "mittn600" },
      { title: "Western Sydney Mitsubishi Trucks", value: "mittn225" },
    ],
  },
};

export default fieldMap;
