import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

export default function SideDrawer(props) {
  const {
    pages,
    quickLinks,
    settings,
    mobileOpen,
    handleDrawerToggle,
    handleLogout,
  } = props;

  const useStyles = makeStyles({
    appBar: {
      color: "white",
      backgroundColor: "#3395e3",
    },
  });

  const navigate = useNavigate();
  const style = useStyles();

  const handleNav = (location) => {
    navigate(`/${location}`);
  };

  return (
    <Drawer
      anchor="right"
      open={mobileOpen}
      variant="temporary"
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "block", sm: "none" },
      }}
    >
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
        <Typography variant="h5" className={style.appBar} sx={{ py: 2 }}>
          NeXUS
        </Typography>
        <Divider />
        <List>
          {pages.map((item) => {
            switch (item) {
              case "Home":
                return (
                  <ListItemButton
                    key={item}
                    sx={{ textAlign: "center" }}
                    onClick={() => handleNav("dashboard")}
                  >
                    <ListItemText primary={item} />
                  </ListItemButton>
                );
              case "Admin": {
                return quickLinks.map((link) => {
                  return (
                    <ListItemButton
                      key={link.title}
                      sx={{ textAlign: "center" }}
                      onClick={() => navigate(`/${link.value}`)}
                    >
                      <ListItemText primary={link.title} />
                    </ListItemButton>
                  );
                });
              }
              case "Invoicing": {
                return settings.map((invoice) => {
                  return (
                    <ListItemButton
                      key={invoice}
                      sx={{ textAlign: "center" }}
                      onClick={() =>
                        navigate(
                          invoice === "Search"
                            ? "/search"
                            : `/invoice/${invoice}`
                        )
                      }
                    >
                      <ListItemText primary={invoice} />
                    </ListItemButton>
                  );
                });
              }
              default:
                return (
                  <ListItemButton
                    key={item}
                    sx={{ textAlign: "center" }}
                    onClick={() => handleNav(item)}
                  >
                    <ListItemText primary={item} />
                  </ListItemButton>
                );
            }
          })}
          <ListItem key="Logout" disablePadding>
            <ListItemButton
              onClick={() => handleLogout()}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}

SideDrawer.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.string),
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  quickLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  settings: PropTypes.arrayOf(PropTypes.string),
};

SideDrawer.defaultProps = {
  pages: ["Home", "Invoicing", "Admin", "CSC Help Manual"],
  quickLinks: [],
  settings: [],
};
