import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, FormControl, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function FormDatePicker(props) {
  const { field, formData, setFormData, readOnly, size, height } = props;
  const [error, setError] = useState(false);
  const minDate = new Date(new Date("01/01/1970").toISOString());

  useEffect(() => {
    setError(field.error);
  }, [field.error]);

  useEffect(() => {
    if (!(field.fieldName in formData)) {
      setFormData({ ...formData, [field.fieldName]: "" });
    } else {
      setFormData({
        ...formData,
        [field.fieldName]: new Date(`${formData[field.fieldName]}`),
      });
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid key={field.fieldName} item xs={size}>
        <FormControl fullWidth>
          <DesktopDatePicker
            disabled={readOnly}
            defaultValue={new Date()}
            id={field.fieldName}
            label={field.fieldTitle}
            name={field.fieldName}
            inputFormat="dd/MM/yyyy"
            mask="__/__/____"
            minDate={minDate}
            value={
              formData[field.fieldName]
                ? new Date(`${formData[field.fieldName]}`)
                : null
            }
            onChange={(currentValue) => {
              let valid = true;
              if (currentValue === null) {
                valid = false;
              } else if (currentValue.toString() === "Invalid Date") {
                valid = false;
              }
              setError(
                (field.required && currentValue === null) ||
                  (currentValue != null &&
                    currentValue.toString() === "Invalid Date") ||
                  currentValue < minDate
              );
              setFormData({
                ...formData,
                [field.fieldName]: valid
                  ? currentValue.toISOString()
                  : currentValue,
              });
            }}
            inputProps={{ "data-testid": "date-picker" }}
            renderInput={(params) => (
              <TextField
                {...params} // eslint-disable-line
                size={height}
                helperText={
                  error &&
                  (field.required && !formData[field.fieldName]
                    ? "Required"
                    : "Invalid Date (dd/mm/yyyy)")
                }
                required={field.required}
                error={error && !readOnly}
              />
            )}
          />
        </FormControl>
      </Grid>
    </LocalizationProvider>
  );
}

FormDatePicker.propTypes = {
  setFormData: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired, //eslint-disable-line
  formData: PropTypes.object.isRequired, //eslint-disable-line
  readOnly: PropTypes.bool.isRequired,
  height: PropTypes.string,
  size: PropTypes.number,
};

FormDatePicker.defaultProps = {
  size: 6,
  height: "normal",
};
