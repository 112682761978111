import section from "../sections";
import InvoiceDetails from "../sections/InvoiceDetails";
import VehicleSchemeDetails from "../sections/VehicleSchemeDetails";

const BusinessWiseCarElectricsInvoice = {
  invoiceTitle: "BusinessWise Car Electrics Invoice Capture",
  serviceType: "Car Electrics",
  view: false,
  sections: [
    InvoiceDetails,
    section({
      sectionTitle: "Car Electrics Job Details",
      fields: ["JobNo", "JobType", "Date", "Location", "CallCompletedDate"],
    }),
    section({
      baseSection: VehicleSchemeDetails,
      fields: ["TelstraDomesticNo", "OdometerReading"],
    }),
  ],
};

export default BusinessWiseCarElectricsInvoice;
