import InvoiceDetails from "../sections/InvoiceDetails";
import BreakdownDetails from "../sections/BreakdownDetails";
import section from "../sections";
import VehicleSchemeDetails from "../sections/VehicleSchemeDetails";

const BusinessWiseBatteryInvoice = {
  invoiceTitle: "BusinessWise Battery Invoice Capture",
  serviceType: "Battery",
  view: false,
  sections: [
    InvoiceDetails,
    section({
      baseSection: BreakdownDetails,
      fields: ["CallCompletedDate"],
    }),
    section({
      baseSection: VehicleSchemeDetails,
      fields: ["TelstraDomesticNo", "OdometerReading"],
    }),
  ],
};

export default BusinessWiseBatteryInvoice;
