import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import FormTextField from "../FormTextField";
import FormDropdown from "../FormDropdown";
import FormDatePicker from "../FormDatePicker";

export default function FormSection(props) {
  const { section, formData, setFormData, readOnly, size } = props;

  return (
    <Grid key={section.sectionTitle} container spacing={2}>
      <Grid item xs={12}>
        <h2>{section.sectionTitle}</h2>
      </Grid>
      {section.fields.map((field) => {
        switch (field.type) {
          case "TextField":
            return (
              <FormTextField
                key={`${section.sectionTitle}-${field.fieldName}`}
                readOnly={readOnly}
                formData={formData}
                setFormData={setFormData}
                field={field}
                size={size}
              />
            );

          case "Dropdown":
            return (
              <FormDropdown
                key={`${section.sectionTitle}-${field.fieldName}`}
                readOnly={readOnly}
                formData={formData}
                setFormData={setFormData}
                field={field}
                size={size}
              />
            );
          case "Date":
            return (
              <FormDatePicker
                key={`${section.sectionTitle}-${field.fieldName}`}
                readOnly={readOnly}
                formData={formData}
                setFormData={setFormData}
                field={field}
                size={size}
              />
            );

          case "Typography":
            return (
              <Grid key={field.fieldTitle} item xs={12}>
                <Typography
                  label={field.fieldTitle}
                  id={field.fieldTitle}
                  name={field.fieldTitle}
                  aria-label="form-typography"
                >
                  {formData[field.fieldTitle]}
                </Typography>
              </Grid>
            );
          default:
            return null;
        }
      })}
    </Grid>
  );
}

FormSection.propTypes = {
  section: PropTypes.object, //eslint-disable-line
  formData: PropTypes.object, //eslint-disable-line
  setFormData: PropTypes.func,
  readOnly: PropTypes.bool,
  size: PropTypes.number,
};

FormSection.defaultProps = {
  section: {},
  formData: {},
  setFormData: () => {},
  readOnly: false,
  size: 6,
};
