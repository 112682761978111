import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Card, CardContent, Typography, Box, Skeleton } from "@mui/material";

import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import ContactEmergencyRoundedIcon from "@mui/icons-material/ContactEmergencyRounded";
import ContactPhoneRoundedIcon from "@mui/icons-material/ContactPhoneRounded";
import FaxRoundedIcon from "@mui/icons-material/FaxRounded";
import HomeWorkRoundedIcon from "@mui/icons-material/HomeWork";
import PersonRoundedIcon from "@mui/icons-material/Person";
import PhoneAndroidRoundedIcon from "@mui/icons-material/PhoneAndroidRounded";

import apiClient from "../../api/apiClient";
import DashboardCardContent from "../DashboardCardContent";

export default function AgentInfo(props) {
  const { info } = props;
  const [agentInfo, setAgentInfo] = useState(info);

  // Getting information from API about the agent
  useEffect(async () => {
    if (Object.keys(agentInfo).length === 1) {
      await apiClient
        .getUserInfo({
          userId: agentInfo.accountNumber,
        })
        .then(async (res) => {
          if (res.status === 200) {
            const rjson = await res.json();
            setAgentInfo(rjson);
          }
        });
    }
  }, []);

  // Returning empty string if there is no details
  const isNull = (input) => {
    if (input === null || input === undefined) {
      return "";
    }
    return input;
  };

  return (
    <Card>
      <CardContent sx={{ p: 2 }}>
        {Object.keys(agentInfo).length > 1 ? (
          <>
            <Box
              sx={{
                width: "100%",
                display: "inline-flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                key="agentName"
                aria-label="agentName"
                variant="h5"
                component="div"
                sx={{ textTransform: "capitalize" }}
              >
                {isNull(agentInfo.name).toLowerCase()}
              </Typography>
              <PersonRoundedIcon fontSize="large" />
            </Box>
            <Typography
              key="agentEmail"
              aria-label="agentEmail"
              gutterBottom
              variant="subtitle1"
              component="div"
            >
              {isNull(agentInfo.email).toLowerCase()}
            </Typography>
            <DashboardCardContent
              key="agent-abn"
              title="ABN"
              value={isNull(agentInfo.abn)}
              Icon={BusinessRoundedIcon}
            />
            <DashboardCardContent
              key="agent-address"
              title="Address"
              value={isNull(agentInfo.address).toLowerCase()}
              Icon={HomeWorkRoundedIcon}
              titleCase
            />
            <DashboardCardContent
              key="agent-phone-no"
              title="Phone No."
              value={isNull(agentInfo.phoneNumber)}
              Icon={ContactPhoneRoundedIcon}
            />
            <DashboardCardContent
              key="agent-fax-no"
              title="Fax No."
              value={isNull(agentInfo.faxNumber)}
              Icon={FaxRoundedIcon}
            />
            <DashboardCardContent
              key="agent-mobile-no"
              title="Mobile No."
              value={isNull(agentInfo.mobileNumber)}
              Icon={PhoneAndroidRoundedIcon}
            />
            <DashboardCardContent
              key="agent-contact-person"
              title="Contact Person"
              value={isNull(agentInfo.contactPerson)}
              Icon={ContactEmergencyRoundedIcon}
            />
          </>
        ) : (
          <>
            <Skeleton
              aria-label="skeleton-text"
              variant="text"
              sx={{ fontSize: "4rem" }}
            />
            <Skeleton
              aria-label="skeleton-rounded"
              variant="rounded"
              height="200px"
            />
          </>
        )}
      </CardContent>
    </Card>
  );
}

AgentInfo.propTypes = {
  info: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
