import { graphConfig } from "../authConfig";
// Note this is only frontend and does not actually grant any privleges
const getAdminStatus = async (accessToken) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);
  headers.append("ConsistencyLevel", "eventual");

  const options = {
    method: "GET",
    headers,
  };

  // Makes API calls to the azure graph to see if the user is an admin or user
  const adminRes = await fetch(graphConfig.admin, options);
  if (adminRes.status === 200) {
    return true;
  }
  const readAdminRes = await fetch(graphConfig.readOnlyAdmin, options);
  if (readAdminRes.status === 200) {
    return true;
  }
  return false;
};

export default getAdminStatus;
