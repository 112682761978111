import React, { useState, useEffect } from "react";
import { Box, Grid, Button, TextField, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";

import CostTable from "../CostTable";
import FormSection from "../FormSection";
import apiClient from "../../api/apiClient";
import Approval from "../../invoices/sections/Approval";
import AgentInfo from "../AgentInfo";
import ErrorPopups from "../ErrorPopups";

export default function InvoiceForm(props) {
  const {
    form,
    readOnly,
    setReadOnly,
    setRejectedInvoice,
    formData,
    setFormData,
    costTableRows,
    setCostTableRows,
    invoiceId,
    invoiceType,
    originalStatus,
    serviceType,
    errors,
    setErrors,
    admin,
  } = props;
  const [currentInvoiceId, setCurrentInvoiceId] = useState(invoiceId);
  const [currentForm, setCurrentForm] = useState(form);
  const [loading, setLoading] = useState(false);
  const [approvalSection, setApprovalSection] = useState(Approval);
  const navigate = useNavigate();

  const authState = useSelector((state) => state.authenticationState.value);

  // Reset all values if the invoice changes
  useEffect(() => {
    const reset = () => {
      if (!form.view) {
        setCostTableRows([]);
        setFormData({});
        setRejectedInvoice(false);
        setReadOnly(false);
      }

      setCurrentForm(form);
    };

    reset();
  }, [form]);

  // Validating the date of a datepicker
  const valiDate = (dateField, dateValue) => {
    return !(
      (dateValue instanceof Date &&
        !Number.isNaN(dateValue) &&
        dateValue.toString() !== "Invalid Date" &&
        dateValue > new Date("01/01/1970")) ||
      (!dateField.required && (dateValue == null || dateValue === ""))
    );
  };

  // Validating form based on regex expressions, etc.
  const validateForm = () => {
    let valErrors = false;
    const newForm = { ...currentForm };
    Object.keys(currentForm.sections).forEach((section) => {
      currentForm.sections[section].fields.forEach((field) => {
        const fieldValue = formData[field.fieldName];
        // This could probably be abstracted
        if (
          (field.pattern && !new RegExp(field.pattern).test(fieldValue)) ||
          (field.required && (fieldValue === "" || fieldValue == null)) ||
          (field.type === "Date" && valiDate(field, fieldValue))
        ) {
          // Set error to true
          newForm.sections[section].fields.filter((f) => {
            return f === field;
          })[0].error = true;
          valErrors = true;
        }
      });
    });
    if (valErrors) {
      setCurrentForm(newForm);
    }
    return !valErrors;
  };

  // Deleting an invoice and then redirecting user to search page
  const deleteInvoice = async () => {
    const responce = await apiClient.deleteInvoice({
      queries: {
        invoiceId: currentInvoiceId,
        serviceType,
        scn: formData.scn,
      },
    });
    if (responce.status === 200) {
      navigate("/search");
    } else {
      const rjson = await responce.json();
      setErrors([
        ...errors,
        { title: "Delete failed", message: rjson.detail, id: uuidv4() },
      ]);
    }
  };

  // Saving invoice and sending data to API client
  const saveInvoice = async () => {
    setLoading(true);
    const rData = await apiClient.saveInvoice({
      token: authState.accessToken,
      body: {
        invoiceId: currentInvoiceId,
        formData,
        invoiceItems: costTableRows,
        invoiceType,
        serviceType,
      },
    });
    setLoading(false);
    return rData;
  };

  // Saving invoice and giving appropriate prompts
  const handleSave = async () => {
    const responce = await saveInvoice();
    const rjson = await responce.json();
    if (responce.status === 200) {
      setErrors([
        ...errors,
        {
          title: "Success",
          message: "Invoice saved",
          id: uuidv4(),
          severity: "success",
        },
      ]);
    } else if (responce.status === 201) {
      setErrors([
        ...errors,
        {
          title: "Success",
          message: "New invoice saved",
          id: uuidv4(),
          severity: "success",
        },
      ]);
      setCurrentInvoiceId(rjson.invoiceId);
    } else {
      setErrors([
        ...errors,
        {
          title: "Failed to Save",
          message:
            typeof rjson.detail === "string"
              ? rjson.detail
              : rjson.detail.error,
          id: uuidv4(),
          severity: "error",
        },
      ]);
    }
    window.scrollTo(0, 0);
  };

  // Changing the status of the invoice (rejected, submitted, approved, etc.)
  const handleSetStatus = async (status) => {
    if (status === "REJECTED" && !formData.RejectReasonCode) {
      const newFields = approvalSection.fields;
      newFields[0] = { ...newFields[0], error: true };
      setApprovalSection({ ...approvalSection, fields: newFields });
      return;
    }
    setLoading(true);
    const response = await apiClient.setInvoiceStatus({
      invoiceId: currentInvoiceId,
      body: {
        scn: formData.scn,
        status,
        invoiceType,
        serviceType,
        RejectReasonCode: formData.RejectReasonCode,
        BMComments: formData.BMComments || "",
      },
    });
    setLoading(false);
    if (response.status === 200) {
      setErrors([
        ...errors,
        {
          title: "Success",
          message: `Invoice ${status.toLowerCase()}`,
          id: uuidv4(),
          severity: "success",
        },
      ]);
      setFormData({ ...formData, InvoiceStatusCode: status });
      navigate(
        `/invoice/view/${invoiceType.toUpperCase()}/${serviceType.toUpperCase()}/${currentInvoiceId}/${status}`
      );
    } else {
      setErrors([
        ...errors,
        {
          title: `Failed to change status to ${status}`,
          id: uuidv4(),
          severity: "error",
        },
      ]);
      window.scrollTo(0, 0);
    }
  };

  // Submitting form
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const saveResponse = await saveInvoice();
      const saveRjson = await saveResponse.json();
      setCurrentInvoiceId(saveRjson.invoiceId);
      const response = await apiClient.setInvoiceStatus({
        invoiceId: saveRjson.invoiceId,
        body: {
          scn: saveRjson.scn,
          status: "SUBMITTED",
          setStatus: formData.InvoiceStatusCode === "EDIT",
          invoiceType,
          serviceType,
        },
      });
      setLoading(false);
      if (response.status === 200) {
        setErrors([]);
        navigate(
          `/invoice/view/${invoiceType.toUpperCase()}/${serviceType.toUpperCase()}/${
            saveRjson.invoiceId
          }/SUBMITTED`
        );
        setFormData({ ...formData, InvoiceStatusCode: "SUBMITTED" });
        setReadOnly(true);
        return;
      }
      if (response.status === 400) {
        const rjson = await response.json();
        let message = rjson.detail.NRMAExceptions.NRMAException[0].Message[0];
        if (message === "No rows were returned - Invoice Item Cursor.") {
          message = "At least one Cost item must be added.";
        }
        setErrors([
          ...errors,
          {
            title: "Submission failed",
            message,
            id: uuidv4(),
          },
        ]);
      }
    } else {
      setErrors([
        ...errors,
        {
          title: "Submission failed",
          id: uuidv4(),
        },
      ]);
    }
    window.scrollTo(0, 0);
  };

  // Go back to main invoice page or search page
  const handleBack = () => {
    if (formData.InvoiceStatusCode === "EDIT") {
      navigate(
        `/invoice/view/${invoiceType.toUpperCase()}/${serviceType.toUpperCase()}/${currentInvoiceId}/${originalStatus}`
      );
    } else {
      navigate("/search");
    }
  };

  return (
    <Box
      component="form"
      minWidth="400px"
      noValidate
      onSubmit={handleSubmit}
      sx={{ p: 0, mt: 3, width: "80vw", ml: "10vw" }}
      xs={12}
    >
      <Grid container spacing={2} item xs={12}>
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={12}>
            <h1>{currentForm.invoiceTitle}</h1>
            <ErrorPopups errors={errors} setErrors={setErrors} />
          </Grid>
        </Grid>
        {currentForm.sections.map((section) => {
          return (
            <FormSection
              key={`${currentForm.invoiceTitle}-${section.sectionTitle}`}
              formData={formData}
              readOnly={readOnly}
              setFormData={setFormData}
              section={section}
            />
          );
        })}

        <h2>Costs</h2>

        <CostTable
          rows={costTableRows}
          setRows={setCostTableRows}
          readOnly={readOnly}
          currentInvoiceTotal={costTableRows.reduce(
            (accumulator, row) => accumulator + row.total,
            0
          )}
        />

        <Box sx={{ mt: "2vh", width: "100%" }}>
          <TextField
            multiline
            disabled={readOnly}
            label="Comments"
            value={formData.AgentComments || ""}
            onChange={(e) =>
              setFormData({
                ...formData,
                AgentComments: e.target.value,
              })
            }
            sx={{ width: "100%" }}
          />
        </Box>
        {((admin && formData.InvoiceStatusCode === "SUBMITTED") ||
          formData.InvoiceStatusCode === "REJECTED") && (
          <FormSection
            key={`${currentForm.invoiceTitle}-Approval`}
            formData={formData}
            readOnly={formData.InvoiceStatusCode !== "SUBMITTED"}
            setFormData={setFormData}
            section={approvalSection}
          />
        )}

        {loading ? (
          <CircularProgress />
        ) : (
          <Box
            minWidth="500px"
            sx={{
              mt: "2vh",
              mb: "2vh",
              display: "flex",
              width:
                formData.InvoiceStatusCode === "SAVED" || !readOnly
                  ? "24vw"
                  : "12vw",
              justifyContent: "flex-start",
              gap: "20px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                handleBack();
              }}
            >
              Back
            </Button>
            {!readOnly && (
              <>
                <Button type="submit" variant="contained">
                  Submit
                </Button>
                {formData.InvoiceStatusCode !== "EDIT" && (
                  <Button onClick={handleSave} variant="contained">
                    Save
                  </Button>
                )}
              </>
            )}

            {(formData.InvoiceStatusCode === "SAVED" ||
              (admin && formData.InvoiceStatusCode === "SUBMITTED")) &&
            formData.InvoiceStatusCode !== "EDIT" ? (
              <Button variant="contained" onClick={deleteInvoice}>
                Delete
              </Button>
            ) : null}

            {formData.InvoiceStatusCode === "SUBMITTED" && admin && (
              <>
                <Button
                  onClick={() => handleSetStatus("APPROVED")}
                  variant="contained"
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSetStatus("REJECTED")}
                >
                  Reject
                </Button>
              </>
            )}

            {(formData.InvoiceStatusCode === "REJECTED" && !admin) ||
            (admin &&
              (formData.InvoiceStatusCode === "APPROVED" ||
                formData.InvoiceStatusCode === "SUBMITTED")) ? (
              <Button
                variant="contained"
                onClick={() => {
                  setFormData({
                    ...formData,
                    InvoiceStatusCode: "EDIT",
                  });
                  setReadOnly(false);
                  setRejectedInvoice(false);
                  navigate(
                    `/invoice/view/${invoiceType.toUpperCase()}/${serviceType.toUpperCase()}/${currentInvoiceId}/EDIT`
                  );
                }}
              >
                Edit
              </Button>
            ) : null}
          </Box>
        )}
        {formData.InvoiceStatusCode !== "SAVED" && admin && (
          <Box width="100%" size={12} sx={{ pb: 4 }}>
            <AgentInfo info={{ accountNumber: formData.AccountNumber }} />
          </Box>
        )}
      </Grid>
    </Box>
  );
}

InvoiceForm.propTypes = {
  form: PropTypes.object.isRequired, //eslint-disable-line
  readOnly: PropTypes.bool,
  setRejectedInvoice: PropTypes.func,

  setReadOnly: PropTypes.func,
  formData: PropTypes.object.isRequired, //eslint-disable-line
  setFormData: PropTypes.func.isRequired,
  costTableRows: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line
  setCostTableRows: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object).isRequired, //eslint-disable-line
  setErrors: PropTypes.func.isRequired,
  invoiceId: PropTypes.string,
  invoiceType: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
  admin: PropTypes.bool,
  originalStatus: PropTypes.string.isRequired,
};

InvoiceForm.defaultProps = {
  invoiceId: null,
  readOnly: false,
  setReadOnly: () => {},
  setRejectedInvoice: () => {},
  admin: false,
};
