const invoiceTypeCode = (invoiceType) => {
  let code = invoiceType.toUpperCase();
  if (invoiceType.toUpperCase() === "NAP") {
    code = "NAP";
  } else if (invoiceType.toUpperCase() === "BUSINESSWISE") {
    code = "BW";
  } else if (invoiceType.toUpperCase() === "MEMBERSHIP") {
    code = "PC";
  }

  return code;
};

export default invoiceTypeCode;
