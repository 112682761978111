import InvoiceDetails from "../sections/InvoiceDetails";
import BreakdownDetails from "../sections/BreakdownDetails";
import TowDetails from "../sections/TowDetails";
import section from "../sections";
import VehicleSchemeDetails from "../sections/VehicleSchemeDetails";

const BusinessWiseTowInvoice = {
  invoiceTitle: "BusinessWise Tow Invoice Capture",
  serviceType: "Tow",
  view: false,
  sections: [
    InvoiceDetails,
    section({
      baseSection: BreakdownDetails,
      fields: ["CallCompletedDate"],
    }),
    section({
      baseSection: VehicleSchemeDetails,
      fields: ["TelstraDomesticNo"],
    }),
    TowDetails,
  ],
};

export default BusinessWiseTowInvoice;
