import React, { useState, useEffect } from "react";
import {
  Checkbox,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

export default function CostTable(props) {
  const { rows, setRows, readOnly, currentInvoiceTotal } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [gstIncluded, setGstIncluded] = useState(true);

  const [quantityError, setQuantityError] = useState(false);
  const [unitPriceError, setUnitPriceError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [invoiceTotal, setInvoiceTotal] = useState(currentInvoiceTotal);

  // Create intial cost table
  const createData = (info, qty, price, gstIncl) => {
    const total = qty * price;
    setInvoiceTotal(invoiceTotal + total);
    return { info, qty, price, gstIncl, total };
  };

  const formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  });

  // Add cost
  const handleAdd = () => {
    closeSnackbar();
    let error = false;

    if (description === "") {
      enqueueSnackbar("Required field missing: Part/Item Description", {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      setDescriptionError(true);
      error = true;
    }

    if (!quantity.match(/^[0-9]*$/) || quantity === "" || quantity === "0") {
      enqueueSnackbar("Invoice line item quantity is invalid", {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      setQuantityError(true);
      error = true;
    }

    if (
      !unitPrice.match(/^\$?[0-9]+(.[0-9][0-9]?)?$/) ||
      unitPrice === "" ||
      unitPrice === "0.00" ||
      unitPrice === "0"
    ) {
      enqueueSnackbar("Unit price is not a valid currency value.", {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      setUnitPriceError(true);
      error = true;
    }
    if (!error) {
      setRows([
        ...rows,
        createData(
          description,
          quantity,
          parseFloat(unitPrice).toFixed(2),
          gstIncluded ? 1 : 0
        ),
      ]);
      setDescription("");
      setQuantity("");
      setUnitPrice("");
      setGstIncluded(true);

      setUnitPriceError(false);
      setQuantityError(false);
      setDescriptionError(false);
    }
  };

  const columns = [
    "Item No. / Part No. / Charge Description *",
    "Qty *",
    "Unit Price *",
    "GST Incl. *",
    "Total Value",
  ];

  useEffect(() => {
    if (rows.length === 0) {
      setInvoiceTotal(0);
    }
  }, [rows]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "50vw" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((columnName) => (
              <TableCell key={columnName}>{columnName}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => {
            // setInvoiceTotal(invoiceTotal + row.total);
            return (
              <TableRow
                key={index} // eslint-disable-line
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  aria-label={`row-info-${index}`}
                >
                  {row.info}
                </TableCell>
                <TableCell>{row.qty}</TableCell>
                <TableCell>{parseFloat(row.price).toFixed(2)}</TableCell>

                <TableCell>
                  <Checkbox checked={!!parseInt(row.gstIncl, 2)} disabled />
                </TableCell>

                <TableCell>{formatter.format(row.total)}</TableCell>

                {!readOnly ? (
                  <TableCell>
                    <IconButton
                      id={index}
                      onClick={() => {
                        const copy = [...rows];
                        copy.splice(index, 1);
                        setRows([...copy]);
                        setInvoiceTotal(invoiceTotal - row.total);
                      }}
                      aria-label="delete-btn"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                ) : null}
              </TableRow>
            );
          })}

          {!readOnly ? (
            <TableRow
              key="inputRow"
              aria-label="input-row"
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell scope="row">
                <TextField
                  value={description}
                  label="Item No. / Part No. / Charge Description"
                  required
                  error={descriptionError}
                  aria-label="add-charge"
                  inputProps={{ "data-testid": "charge-input" }}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </TableCell>

              <TableCell>
                <TextField
                  value={quantity}
                  label="Qty"
                  required
                  error={quantityError}
                  aria-label="add-quantity"
                  inputProps={{ "data-testid": "quantity-input" }}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                />
              </TableCell>

              <TableCell>
                <TextField
                  label="Unit Price"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    "data-testid": "price-input",
                  }}
                  value={unitPrice}
                  error={unitPriceError}
                  aria-label="add-price"
                  onChange={(e) => setUnitPrice(e.target.value)}
                />
              </TableCell>

              <TableCell>
                <Checkbox
                  checked={gstIncluded}
                  onChange={(e) => setGstIncluded(e.target.checked)}
                  aria-label="add-dupe-checkbox"
                />
              </TableCell>

              <TableCell />

              <TableCell>
                <IconButton
                  color="primary"
                  onClick={handleAdd}
                  aria-label="add-cost-btn"
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ) : null}

          <TableRow>
            {columns.map((columnName) => {
              return columnName === "Total Value" ? (
                <TableCell key={columnName} aria-label="total-cost">
                  {formatter.format(invoiceTotal)}
                </TableCell>
              ) : (
                <TableCell key={columnName} />
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CostTable.propTypes = {
  setRows: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line
  readOnly: PropTypes.bool,
  currentInvoiceTotal: PropTypes.number,
};

CostTable.defaultProps = {
  readOnly: false,
  currentInvoiceTotal: 0,
};
