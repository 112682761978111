import PropTypes from "prop-types";

import fieldMap from "./fieldMap";

export default function field(props) {
  const {
    fieldTitle,
    required = false,
    type = "TextField",
    characterLimit = 64,
  } = props;
  if (fieldTitle in fieldMap) {
    return fieldMap[fieldTitle];
  }
  return {
    fieldTitle,
    required,
    type,
    characterLimit,
  };
}

field.propTypes = {
  fieldTitle: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  characterLimit: PropTypes.number,
};
