import section from "../sections";
import InvoiceDetails from "../sections/InvoiceDetails";
import NAPSchemeDetails from "../sections/NAPSchemeDetails";
// import BreakdownDetails from "../Sections/BreakdownDetails";

const NapBatteryInvoice = {
  invoiceTitle: "NAP Battery Invoice Capture",
  serviceType: "Battery",
  view: false,
  sections: [
    InvoiceDetails,
    section({
      sectionTitle: "Breakdown Details",
      fields: ["CADJobNo", "BreakdownDate", "BreakdownLocation"],
    }),
    section({
      baseSection: NAPSchemeDetails,
      fields: ["OdometerReading"],
    }),
  ],
};

export default NapBatteryInvoice;
