import React from "react";

import { Box, Container, CircularProgress, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import theme from "../../utils/appTheme";

import Banner from "../../components/Banner";
import Copyright from "../../components/Copyright";

export default function Loading() {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="false" disableGutters>
        <Banner showBarElements={false} />
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress size="15vw" />
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </Container>
    </ThemeProvider>
  );
}
