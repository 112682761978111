import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes, { bool } from "prop-types";
import { v4 as uuidv4 } from "uuid";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import apiClient from "../../api/apiClient";
import { setPaginationState } from "../../features/paginationState/paginationState";

export default function ApprovalPopup(props) {
  const {
    invoice,
    errors,
    setErrors,
    setLoading,
    invoiceState,
    setInvoiceState,
  } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector(
    (state) => state.paginationState.value.filterOptions
  );

  const approve = async () => {
    setLoading(true);
    const response = await apiClient.setInvoiceStatus({
      invoiceId: invoice.invoiceId,
      body: {
        scn: invoice.scn,
        status: "APPROVED",
      },
    });
    setInvoiceState(!invoiceState);
    setLoading(false);
    if (response.status === 200) {
      setErrors([
        ...errors,
        {
          title: "Success",
          message: "Invoice Approved",
          id: uuidv4(),
          severity: "success",
        },
      ]);
    } else {
      setErrors([
        ...errors,
        {
          title: `Failed to change status to APPROVED`,
          id: uuidv4(),
          severity: "error",
        },
      ]);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <Box>
        <Dialog
          key={`${invoice.invoiceId}-dialog`}
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle key={`${invoice.invoiceId}-dialog-approve-title`}>
            Are you sure?
          </DialogTitle>
          <DialogContent>
            <DialogContentText key={`${invoice.invoiceId}-dialog-approve-desc`}>
              This action will approve the invoice. Double check if the values
              are correct.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              autoFocus
            >
              Close
            </Button>
            <Button
              onClick={() => {
                approve();
                setOpen(false);
                dispatch(
                  setPaginationState({
                    filterOptions: filters,
                    index: 0,
                  })
                );
              }}
            >
              Approve
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Button
        key={`${invoice.invoiceId}-approve-btn`}
        title="APPROVE"
        className="table-button"
        onClick={() => {
          setOpen(true);
        }}
      >
        Approve
      </Button>
    </>
  );
}

ApprovalPopup.propTypes = {
  invoice: PropTypes.oneOfType([PropTypes.object]).isRequired, //eslint-disable-line
  errors: PropTypes.arrayOf(PropTypes.object).isRequired, //eslint-disable-line
  setErrors: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  invoiceState: bool.isRequired,
  setInvoiceState: PropTypes.func.isRequired,
};
