import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

export default function FormTextField(props) {
  const { field, formData, setFormData, readOnly, size, height } = props;
  const [error, setError] = useState(false);

  // Setting an empty string if the field doesn't exist in formData
  useEffect(() => {
    if (!(field.fieldName in formData)) {
      setFormData({ ...formData, [field.fieldName]: "" });
    }
  }, []);

  useEffect(() => {
    setError(field.error);
  }, [field.error]);

  // When the textfield changes, update the formData. Sets errors if not in correct pattern
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [field.fieldName]: e.target.value,
    });
    if (!(field.pattern && !new RegExp(field.pattern).test(e.target.value))) {
      setError(false);
    }
  };

  // Getting value that is shown in textfield
  const startVal = () => {
    if (formData[field.fieldName] === "" || !formData[field.fieldName]) {
      return "";
    }
    return formData[field.fieldName];
  };

  return (
    <Grid key={field.fieldName} item xs={size}>
      <TextField
        multiline={field.multiline}
        aria-label={`form-textfield-${field.fieldName}`}
        disabled={readOnly}
        required={field.required}
        fullWidth
        label={field.fieldTitle}
        id={field.fieldName}
        name={field.fieldTitle}
        error={error && !readOnly}
        onChange={handleChange}
        value={startVal()}
        size={height}
        helperText={
          error &&
          (field.required && formData[field.fieldName] === ""
            ? "Required"
            : field.errorMessage)
        }
        inputProps={{
          maxLength: field.characterLimit,
        }}
        InputProps={{ // eslint-disable-line
          pattern: field.pattern,
          endAdornment: (
            <InputAdornment position="end">{field.endAdornment}</InputAdornment>
          ),
        }}
        onBlur={(e) => {
          setError(
            (field.pattern &&
              !new RegExp(field.pattern).test(e.target.value)) ||
              (field.required && e.target.value === "")
          );
        }}
      />
    </Grid>
  );
}

FormTextField.propTypes = {
  setFormData: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired, //eslint-disable-line
  formData: PropTypes.object.isRequired, //eslint-disable-line
  readOnly: PropTypes.bool.isRequired,
  size: PropTypes.number,
  height: PropTypes.string,
};

FormTextField.defaultProps = {
  size: 6,
  height: "normal",
};
