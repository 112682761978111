const exportCSV = (
  columnNames,
  rows,
  name = "BmBwMembershipFusoSalesReport"
) => {
  let data = `${columnNames.map((col) => col.title).join(",")}\n`;
  data += rows
    .map((row) => {
      return columnNames.map((col) => row[col.value]).join(",");
    })
    .join("\n");
  const blob = new Blob([data], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = `${name}.csv`;
  link.href = url;
  link.click();
};

export default exportCSV;
