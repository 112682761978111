import React from "react";
import PropTypes from "prop-types";
import { Alert, AlertTitle } from "@mui/material";

export default function ErrorPopups(props) {
  const { errors, setErrors } = props;
  return errors.map((error, index) => {
    return (
      <Alert
        onClose={() => {
          setErrors([...errors.slice(0, index), ...errors.slice(index + 1)]);
        }}
        key={`${error.message}-error-${error.id}`}
        severity={error.severity || "error"}
      >
        <AlertTitle>{error.title}</AlertTitle>
        {error.message}
      </Alert>
    );
  });
}

ErrorPopups.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line
  setErrors: PropTypes.func.isRequired,
};
