import React from "react";
import PropTypes, { bool } from "prop-types";
import {
  Box,
  TableRow,
  TableCell,
  Checkbox,
  Button,
  Divider,
} from "@mui/material";
import LinkButton from "../LinkButton";
import ApprovalPopup from "../ApprovalPopup";
import RejectionPopup from "../RejectionPopup";

/*
    {
      invoiceType: "PC",
      serviceType: "TOW",
      invoiceDate: "01/09/2017",
      invoiceNo: "inv105465",
      cadJobNo: "20170825001672",
      registration: "YEE157",
      breakdownDate: "25/08/2017",
      breakdownLocation: "COILA",
      totalValue: 210.0,
      statusChanged: "07/10/2017",
      status: "SUBMITTED",
      actions: "EDIT",
    }
*/

export default function SearchResultinvoice(props) {
  const {
    invoice,
    admin,
    errors,
    setErrors,
    setLoading,
    invoiceState,
    setInvoiceState,
  } = props;
  const formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  });

  const isDuplicate = (flag) => {
    if (flag === 0) {
      return false;
    }
    return true;
  };

  // Possible actions given invoice status
  const actions = () => {
    if (invoice.InvoiceStatusCode === "SAVED") {
      return ["EDIT"];
    }

    if (invoice.InvoiceStatusCode === "SUBMITTED") {
      if (admin) {
        return ["APPROVE", "REJECT"];
      }
      return ["NO ACTION"];
    }

    const noAction = ["APPROVED", "REJECTED", "PENDING PAYMENT"];

    if (noAction.includes(invoice.InvoiceStatusCode)) {
      return ["NO ACTION"];
    }
    return [];
  };

  return (
    <TableRow
      key={invoice.invoiceId}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      {admin && <TableCell>{invoice.AgentName}</TableCell>}
      <TableCell>{invoice.InvoiceType}</TableCell>
      <TableCell>{invoice.ServiceType}</TableCell>
      <TableCell>
        {new Date(
          new Date(invoice.InvoiceDate) -
            new Date().getTimezoneOffset() * 60 * 1000
        ).toLocaleDateString("en-AU")}
      </TableCell>
      <TableCell>{invoice.AgentInvoiceReference}</TableCell>
      <TableCell>{invoice.CadJobNumber}</TableCell>
      <TableCell>{invoice.RegistrationNumber}</TableCell>
      <TableCell>
        {new Date(
          new Date(invoice.BreakdownDate) -
            new Date().getTimezoneOffset() * 60 * 1000
        ).toLocaleDateString("en-AU")}
      </TableCell>
      <TableCell>{invoice.BreakdownLoc}</TableCell>
      <TableCell>{formatter.format(invoice.TotalCost)}</TableCell>
      {admin && (
        <TableCell>
          <Checkbox disabled checked={isDuplicate(invoice.DuplicateFlag)} />
        </TableCell>
      )}
      <TableCell>
        {new Date(invoice.StatusChangeDate).toLocaleDateString("EN-AU")}
      </TableCell>
      <TableCell>
        <LinkButton
          key={`${invoice.invoiceId}-view`}
          title={invoice.InvoiceStatusCode}
          link={`/invoice/view/${invoice.InvoiceType}/${invoice.ServiceType}/${invoice.invoiceId}/${invoice.InvoiceStatusCode}`}
          className="table-button"
        />
      </TableCell>
      <TableCell>
        {admin &&
          actions().map((action) => {
            switch (action) {
              case "EDIT":
                return (
                  <LinkButton
                    key={`${invoice.invoiceId}-edit`}
                    title="EDIT"
                    link={`/invoice/view/${invoice.InvoiceType}/${invoice.ServiceType}/${invoice.invoiceId}/${invoice.InvoiceStatusCode}`}
                    className="table-button"
                  />
                );
              case "APPROVE":
                return (
                  <Box key={`${invoice.invoiceId}-boxapprove`}>
                    <ApprovalPopup
                      key={`${invoice.invoiceId}-approval-popup`}
                      invoice={invoice}
                      errors={errors}
                      setErrors={setErrors}
                      setLoading={setLoading}
                      invoiceState={invoiceState}
                      setInvoiceState={setInvoiceState}
                    />
                    <Divider />
                  </Box>
                );
              case "REJECT":
                return (
                  <RejectionPopup
                    key={`${invoice.invoiceId}-rejection-popup`}
                    invoice={invoice}
                    errors={errors}
                    setErrors={setErrors}
                    setLoading={setLoading}
                    invoiceState={invoiceState}
                    setInvoiceState={setInvoiceState}
                  />
                );
              case "NO ACTION":
                return (
                  <Button key={`${invoice.invoiceId}-no-action`} disabled>
                    No Action
                  </Button>
                );
              default:
                return <Button disabled>No Action</Button>;
            }
          })}
        {!admin &&
          actions().map((action) => {
            switch (action) {
              case "EDIT":
                return (
                  <LinkButton
                    key={`${invoice.invoiceId}-edit`}
                    title="EDIT"
                    link={`/invoice/view/${invoice.InvoiceType}/${invoice.ServiceType}/${invoice.invoiceId}/${invoice.InvoiceStatusCode}`}
                    className="table-button"
                  />
                );
              case "NO ACTION":
                return (
                  <Button key={`${invoice.invoiceId}-no-action`} disabled>
                    No Action
                  </Button>
                );
              default:
                return <Button disabled>No Action</Button>;
            }
          })}
      </TableCell>
    </TableRow>
  );
}

SearchResultinvoice.propTypes = {
  invoice: PropTypes.oneOfType([PropTypes.object]).isRequired,
  admin: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.object).isRequired, //eslint-disable-line
  setErrors: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  invoiceState: bool.isRequired,
  setInvoiceState: PropTypes.func.isRequired,
};

SearchResultinvoice.defaultProps = {
  admin: false,
};
