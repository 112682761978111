import API from "./API";

// List of API calls/requests
const apiClient = {
  getUserInfo: async ({ userId }) => {
    const r = await API.request({
      path: `users/${userId}`,
    });
    return r;
  },

  getUsers: async () => {
    const r = await API.request({
      path: `users`,
    });
    return r;
  },

  getInvoices: async ({ queries }) => {
    const r = await API.request({
      path: "invoices",
      queries,
    });
    return r;
  },

  getInvoice: async ({ invoiceId, queries }) => {
    const r = await API.request({
      path: `invoices/${invoiceId}`,
      queries, // change this
    });
    return r;
  },

  saveInvoice: async ({ body }) => {
    const r = await API.request({
      path: "invoices",
      body,
      method: "POST",
    });
    return r;
  },

  deleteInvoice: async ({ queries }) => {
    const r = await API.request({
      path: `invoices/${queries.invoiceId}`,
      queries,
      method: "DELETE",
    });
    return r;
  },

  getNAPSchemes: async ({ queries }) => {
    const r = await API.request({
      path: `schemes`,
      queries,
      method: "GET",
    });
    return r;
  },

  getRejectReasons: async ({ queries }) => {
    const r = await API.request({
      path: `rejectReasons`,
      queries,
      method: "GET",
    });
    return r;
  },

  setInvoiceStatus: async ({ invoiceId, body }) => {
    const r = await API.request({
      path: `invoices/${invoiceId}`,
      body,
      method: "PUT",
    });
    return r;
  },

  getFUSOReport: async ({ queries }) => {
    const r = await API.request({
      path: `report`,
      queries,
      method: "GET",
    });
    return r;
  },
};

export default apiClient;
