import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import isLoggedin from "../../utils/isLoggedIn";
import { LoadingScreen } from "../../pages";
import { setAuthenticationState } from "../../features/authenticationState/authenticationState";

export default function PrivateRoute() {
  const [auth, setAuth] = useState(false);
  const [awaiting, setAwaiting] = useState(true);
  const dispatch = useDispatch();
  const authenticationState = useSelector(
    (state) => state.authenticationState.value
  );

  // Check if the user is logged in and is an admin
  useEffect(async () => {
    let isMounted = true;
    const getLoggedIn = () => {
      isLoggedin(dispatch, authenticationState, setAuthenticationState)
        .then((val) => {
          if (isMounted) {
            setAuth(val);
          }
        })
        .catch(() => setAuth(false))
        .finally(() => setAwaiting(false));
      return () => {
        isMounted = false;
      };
    };
    getLoggedIn();
  }, []);

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  if (!awaiting) {
    return auth ? (
      <Outlet />
    ) : (
      <Navigate
        to="/login"
        state={{
          title: "Error",
          message: "This account does not have access to Nexus",
          id: "noperm",
        }}
      />
    );
  }
  return <LoadingScreen />;
}
