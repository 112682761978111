import { getStoredState, REHYDRATE } from "redux-persist";

export default function crossBrowserListener(store, persistConfig) {
  // A listener between open tabs that allows individuals to work with multiple tabs without needing to constantly log in
  return async function persist() {
    const state = await getStoredState(persistConfig);

    store.dispatch({
      type: REHYDRATE,
      key: persistConfig.key,
      payload: state,
    });
  };
}
