import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Container, Typography, Box, Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import apiClient from "../../api/apiClient";
import theme from "../../utils/appTheme";
import exportCSV from "../../utils/exportCSV";

import Banner from "../../components/Banner";
import ErrorPopups from "../../components/ErrorPopups";
import FormSection from "../../components/FormSection";
import ReportTable from "../../components/ReportTable";

import ReportCriteria from "../../invoices/sections/ReportCriteria";
import NoPermission from "../NoPermissions";

const columnNames = [
  { title: "FUSO", value: "FUSO" },
  { title: "FUSO Sales Reference", value: "salesReference" },
  { title: "Date Of Sale", value: "salesDate" },
  { title: "Company/Business Name", value: "name" },
  { title: "Vehicle Registration", value: "registration" },
  { title: "VIN", value: "VIN" },
  { title: "Vehicle Model", value: "vehicleModel" },
  { title: "Vehicle Option (Subscription) Chosen", value: "vehicleOption" },
];

export default function BmBwMembershipFusoSalesReport() {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState([]);
  const [sales, setSales] = useState([]);
  const admin = sessionStorage.getItem("admin") === "true";
  const userAuth = useSelector((state) => state.authenticationState.value);
  const navigate = useNavigate();

  // If there is no access token, the user should be redirected to the login page
  useEffect(() => {
    if (userAuth.accessToken === undefined) {
      navigate("/login");
    }
  });

  // If the user is not an admin, they cannot view this page
  if (!admin) {
    return <NoPermission />;
  }

  // Using export function to create a excel file with the data in it
  const exportData = () => {
    exportCSV(columnNames, sales);
  };

  // Submits the form and shows all the data about the reports using API call
  const handleSubmit = async (e) => {
    e.preventDefault();
    const queries = {
      accountNo: formData.Dealer || "",
      fromDate: formData.FromDate ? formData.FromDate.toJSON() : "",
      toDate: formData.ToDate ? formData.ToDate.toJSON() : "",
    };
    const response = await apiClient.getFUSOReport({ queries });
    const rjson = await response.json();
    if (response.status === 200) {
      setSales(rjson.rows);
    } else {
      setErrors([
        ...errors,
        {
          title: rjson.title,
          message:
            typeof rjson.detail === "string"
              ? rjson.detail
              : rjson.detail.error,
          id: Math.floor(Math.random() * 1000),
        },
      ]);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="false" disableGutters>
        <Banner showBarElements />
        <Typography
          align="center"
          variant="h3"
          sx={{ p: "2vh", color: "#3395e3" }}
        >
          NRMA BusinessWise Membership & Subscription Sales through FUSO
          Dealerships
        </Typography>
        <ErrorPopups errors={errors} setErrors={setErrors} />
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ p: 0, mt: 3, width: "80vw", ml: "10vw" }}
          xs={12}
        >
          <FormSection
            formData={formData}
            setFormData={setFormData}
            section={ReportCriteria}
            size={4}
          />
          <Box
            minWidth="200px"
            sx={{
              pt: 2,
              display: "inline-flex",
              justifyContent: "space-between",
            }}
          >
            <Button type="submit" variant="contained">
              Show
            </Button>
            <Button onClick={exportData} variant="contained">
              Export
            </Button>
          </Box>
        </Box>
        <Box sx={{ p: 0, mt: 3, mb: 5, width: "80vw", ml: "10vw" }} xs={12}>
          {sales.length > 0 && (
            <ReportTable columnNames={columnNames} sales={sales} />
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
