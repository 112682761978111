import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import crossBrowserListener from "../utils/reduxpersist-listener";

import authenticationStateReducer from "../features/authenticationState/authenticationState";
import dropdownOptionsStateReducer from "../features/dropdownOptionsState/dropdownOptionsState";
import paginationStateReducer from "../features/paginationState/paginationState";
import searchStateReducer from "../features/searchState/searchState";
import userInfoStateReducer from "../features/userInfoState/userInfoState";

const reducers = combineReducers({
  authenticationState: authenticationStateReducer,
  searchState: searchStateReducer,
  userInfoState: userInfoStateReducer,
  dropdownOptionsState: dropdownOptionsStateReducer,
  paginationState: paginationStateReducer,
});

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

window.addEventListener("storage", crossBrowserListener(store, persistConfig));

export default store;
