import { createSlice } from "@reduxjs/toolkit";

/*
value is the json returned by /getUserInfo endpoint
{
  name
  address
  suburb
  abn
  state
  postcode
  phoneNumber
  faxNumber
  mobileNumber
  email
  contactPerson
}

*/

export const userInfoStateSlice = createSlice({
  name: "userInfoState",
  initialState: {
    value: {},
  },
  reducers: {
    setUserInfoState: (state, action) => {
      state.value = action.payload; //eslint-disable-line
    },
  },
});

export const { setUserInfoState } = userInfoStateSlice.actions;

export default userInfoStateSlice.reducer;
