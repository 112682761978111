import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Container, Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { setUserInfoState } from "../../features/userInfoState/userInfoState";
import apiClient from "../../api/apiClient";
import theme from "../../utils/appTheme";
import exportCSV from "../../utils/exportCSV";
import searchUtils from "../../utils/searchUtils";

import Banner from "../../components/Banner";
import ErrorPopups from "../../components/ErrorPopups";
import SearchFilter from "../../components/SearchFilter";
import SearchResults from "../../components/SearchResults";

import Loading from "../loading";

// Makes API call to get invoices given the rows needed and the filter options
const getInvoices = async (fetchedRows, filterOptions) => {
  return apiClient.getInvoices({
    queries: {
      PageNumber: Math.round(
        fetchedRows.length /
          (searchUtils.invoicesPerPage * searchUtils.pagesPerFetch) +
          1
      ),
      RecordsPerPage: searchUtils.invoicesPerPage * searchUtils.pagesPerFetch,
      ...filterOptions,
    },
  });
};

export default function SearchPage() {
  const admin = sessionStorage.getItem("admin") === "true";
  const accessToken = sessionStorage.getItem("accessToken");

  const navigate = useNavigate();

  const [fetchedRows, setFetchedRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const dispatch = useDispatch();
  const [filterOptions, setFilterOptions] = useState(
    useSelector((state) => state.paginationState.value.filterOptions)
  );
  const [index, setIndex] = useState(
    useSelector((state) => state.paginationState.value.index)
  );
  const userInfo = useSelector((state) => state.userInfoState.value);
  const userAuth = useSelector((state) => state.authenticationState.value);

  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [endResultIndex, setEndResultIndex] = useState(0);
  const [info, setInfo] = useState(userInfo);
  const [errors, setErrors] = useState([]);
  const [invoiceState, setInvoiceState] = useState(false);

  const getDate = (date) => {
    const empty = [null, undefined, ""];
    if (empty.includes(date) || date.toString() === "Invalid Date") {
      return "";
    }
    return new Date(date);
  };

  // If there is no access token, the user should be redirected to the login page
  useEffect(() => {
    if (userAuth.accessToken === undefined) {
      navigate("/login");
    }
  });

  /* Getting the information of the client by performing an API call.
    It also dispatches it into the redux store so there is no need to make another call if info needed.
  */
  // UserID currently hardcoded (need to enquire about this)
  useEffect(async () => {
    if (Object.keys(info).length === 0) {
      setPageLoad(true);
    }

    let isMounted = true;

    await apiClient
      .getUserInfo({
        accessToken,
        userId: userAuth.accountNumber,
      })
      .then(async (res) => {
        if (res.status === 200) {
          const rjson = await res.json();
          if (isMounted) {
            setInfo(rjson);
            dispatch(setUserInfoState(rjson));
          }
        }
      })
      .catch(() => {});
    setPageLoad(false);

    return () => {
      isMounted = false;
    };
  }, []);

  // Opening new window for business motoring
  useEffect(() => {
    if (window.location.pathname === "/businessMotoring") {
      window.open("http://www.nrmabusinessmotoring.com.au/ ");
      navigate("/search");
    }
  });

  // Using the above API call to load results and invoices when the page index is changed
  useEffect(async () => {
    // load new pages halfway through if not loading
    let isMounted = true;
    if (
      index >= 0 &&
      index >=
        fetchedRows.length -
          searchUtils.invoicesPerPage *
            Math.floor(searchUtils.pagesPerFetch / 2) &&
      !(
        fetchedRows.length &&
        fetchedRows.length +
          searchUtils.invoicesPerPage * searchUtils.pagesPerFetch >=
          endResultIndex
      ) &&
      !loading
    ) {
      setLoading(true);
      await getInvoices(fetchedRows, {
        ...filterOptions,
        ...(filterOptions.FromDate !== undefined
          ? { FromDate: getDate(filterOptions.FromDate) }
          : {}),
        ...(filterOptions.ToDate !== undefined
          ? { ToDate: getDate(filterOptions.ToDate) }
          : {}),
      })
        .then(async (res) => {
          if (res.status === 200) {
            const rjson = await res.json();
            if (isMounted) {
              setFetchedRows([...fetchedRows, ...rjson.invoices]);
              setEndResultIndex(rjson.totalRecords);
              setLoading(false);
            }
          }
        })
        .catch(() => {});
    }

    return () => {
      isMounted = false;
    };
  }, [index]);

  // When rows and index are changed, the invoices should change (e.g. page 2 corresponds to invoice 6-10)
  useEffect(() => {
    setFilteredRows(
      fetchedRows.slice(index, index + searchUtils.invoicesPerPage)
    );
  }, [fetchedRows, index]);

  // Using the above API call to load results and invoices when an invoice state or search filter options is changed
  useEffect(async () => {
    let isMounted = true;
    if (Object.entries(filterOptions).length > 1) {
      setLoading(true);
      await getInvoices([], {
        ...filterOptions,
        ...(filterOptions.FromDate !== undefined
          ? { FromDate: getDate(filterOptions.FromDate) }
          : {}),
        ...(filterOptions.ToDate !== undefined
          ? { ToDate: getDate(filterOptions.ToDate) }
          : {}),
      })
        .then(async (res) => {
          if (res.status === 200) {
            const rjson = await res.json();
            if (isMounted) {
              setFetchedRows(rjson.invoices);
              setEndResultIndex(rjson.totalRecords);
              setLoading(false);
            }
          }
        })
        .catch(() => {});
      setIndex(0);
    }
    return () => {
      isMounted = false;
    };
  }, [invoiceState, filterOptions]);

  // Get all the invoices in the database
  const getAllInvoices = async () => {
    const res = await getInvoices([], {
      ...filterOptions,
      ...(filterOptions.FromDate !== undefined
        ? { FromDate: getDate(filterOptions.FromDate).toJSON() }
        : {}),
      ...(filterOptions.ToDate !== undefined
        ? { ToDate: getDate(filterOptions.ToDate).toJSON() }
        : {}),
      PageNumber: 0,
    });

    let isMounted = true;

    if (res.status === 200) {
      const rjson = await res.json();
      if (isMounted) {
        setFetchedRows(rjson.invoices);
        setEndResultIndex(rjson.totalRecords);
      }
      return rjson.invoices;
    }
    const rjson = res.json();
    setErrors([
      ...errors,
      {
        title: "Failed to fetch invoices",
        message:
          typeof rjson.detail === "string" ? rjson.detail : rjson.detail.error,
        id: uuidv4(),
      },
    ]);
    isMounted = false;
    return [];
  };

  // Export all invoice data to CSV form
  const exportData = async () => {
    setLoading(true);
    let invoices = fetchedRows;
    if (invoices.length !== endResultIndex) {
      invoices = await getAllInvoices();
    }
    exportCSV(
      [
        { title: "CSC Name", value: "AgentName" },
        { title: "InvoiceType", value: "InvoiceType" },
        { title: "ServiceType", value: "ServiceType" },
        { title: "Invoice Date", value: "InvoiceDate" },
        { title: "CSCInvoiceNumber", value: "AgentInvoiceReference" },
        { title: "ERA Jobn Number", value: "CadJobNumber" },
        { title: "Breakdown Location", value: "BreakdownLoc" },
        { title: "Breakdown Date", value: "BreakdownDate" },
        { title: "Rego", value: "RegistrationNumber" },
        { title: "Membership Number", value: "MembershipNumber" },
        { title: "Claim Completion Date", value: "StatusChangeDate" },
        { title: "Amount (inv. GST)", value: "TotalCost" },
      ],
      invoices,
      "Invoices"
    );
    setLoading(false);
  };

  // Approve all the submitted invoices in the database
  const approveAll = async () => {
    setLoading(true);
    let invoices = fetchedRows;
    let isMounted = true;
    if (invoices.length !== endResultIndex) {
      invoices = await getAllInvoices();
    }
    invoices
      .filter((inv) => {
        return inv.InvoiceStatusCode === "SUBMITTED";
      })
      .forEach(async (inv) => {
        const res = await apiClient.setInvoiceStatus({
          invoiceId: inv.invoiceId,
          body: {
            scn: inv.scn,
            status: "APPROVED",
            BMComments: "",
          },
        });
        if (res.status !== 200) {
          const rjson = await res.json();
          if (isMounted) {
            setErrors([
              ...errors,
              {
                title: rjson.title,
                message:
                  typeof rjson.detail === "string"
                    ? rjson.detail
                    : rjson.detail.error,
                id: uuidv4(),
              },
            ]);
          }
        }
      });
    await getAllInvoices();
    setLoading(false);
    return () => {
      isMounted = false;
    };
  };

  // If busy waiting for API call, the page should show that it is loading
  if (pageLoad) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="false" disableGutters>
        <Banner showBarElements admin={admin} />
        <Box sx={{ p: "2vh" }}>
          <Typography align="center" variant="h3" sx={{ color: "#3395e3" }}>
            {info.name}
          </Typography>
          <Typography variant="h5" sx={{ color: "#3395e3" }}>
            Search Invoices
          </Typography>
          <ErrorPopups errors={errors} setErrors={setErrors} />
          <SearchFilter
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            admin={admin}
            loading={loading}
          />
          {endResultIndex > 0 && (
            <SearchResults
              invoices={filteredRows}
              index={index}
              setIndex={setIndex}
              endResultIndex={endResultIndex}
              admin={admin}
              exportData={exportData}
              approveAll={approveAll}
              errors={errors}
              setErrors={setErrors}
              setLoading={setPageLoad}
              invoiceState={invoiceState}
              setInvoiceState={setInvoiceState}
            />
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
