import InvoiceDetails from "../sections/InvoiceDetails";
import section from "../sections";
import NAPSchemeDetails from "../sections/NAPSchemeDetails";
// import BreakdownDetails from "../Sections/BreakdownDetails";

const NapOtherInvoice = {
  invoiceTitle: "NAP Other Invoice Capture",
  serviceType: "Other",
  view: false,
  sections: [
    InvoiceDetails,
    section({
      sectionTitle: "Breakdown Details",
      fields: ["CADJobNo", "BreakdownDate", "Location"],
    }),
    NAPSchemeDetails,
  ],
};

export default NapOtherInvoice;
