import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./app/store";
import routes from "./routes";
import { msalConfig } from "./authConfig";
import { LoginPage } from "./pages";
import NotFound from "./pages/NotFound";

const msalInstance = new PublicClientApplication(msalConfig);
const persistor = persistStore(store);

(async () => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MsalProvider instance={msalInstance}>
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route
                  key="/login"
                  exact
                  path="/login"
                  element={<LoginPage />}
                />
                {await routes()}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </SnackbarProvider>
        </MsalProvider>
      </PersistGate>
    </Provider>,

    document.getElementById("root")
  );
})();
