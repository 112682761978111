import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

import { setDropdownOptionsState } from "../../features/dropdownOptionsState/dropdownOptionsState";

export default function FormDropdown(props) {
  const { field, formData, setFormData, readOnly, size, height } = props;
  const [error, setError] = useState(false);
  const [options, setOptions] = useState([]);
  const defaultValue =
    typeof field.default === "number" ? field.default : field.default || "";

  const dispatch = useDispatch();
  const selector = useSelector((state) => state.dropdownOptionsState.value);
  const [dropdownOptions, setDropdownOptions] = useState(selector);

  // Setting the default value of a field if not in the formdata
  useEffect(() => {
    if (!(field.fieldName in formData)) {
      if (typeof field.default === "number") {
        setFormData({
          ...formData,
          [field.fieldName]: 0,
        });
      } else {
        setFormData({
          ...formData,
          [field.fieldName]: "",
        });
      }
    }
  }, []);

  // Get the options of a dropdown form
  useEffect(async () => {
    if (typeof field.options === "function") {
      if (dropdownOptions && dropdownOptions[field.fieldName]) {
        setOptions(dropdownOptions[field.fieldName]);
      } else {
        const opts = await field.options();

        setOptions(opts);
        setDropdownOptions({ ...dropdownOptions, [field.fieldName]: opts });
        dispatch(
          setDropdownOptionsState({
            ...dropdownOptions,
            [field.fieldName]: opts,
          })
        );
      }
    } else {
      setOptions(field.options);
    }
  }, []);

  useEffect(() => {
    setError(field.error);
  }, [field.error]);

  // Getting the value that is shown on the dropdown
  const startVal = () => {
    if (
      (defaultValue === "" || defaultValue === 0 || defaultValue) &&
      (formData[field.fieldName] === "" || !formData[field.fieldName])
    ) {
      return defaultValue;
    }

    if (
      defaultValue === null &&
      (formData[field.fieldName] === "" || !formData[field.fieldName])
    ) {
      return "";
    }
    return formData[field.fieldName];
  };

  return (
    <Grid key={field.fieldName} item xs={size}>
      <FormControl
        fullWidth
        required={field.required}
        error={error && !readOnly}
        helpertext="Required"
        aria-label="form-dropdown"
      >
        <InputLabel id="demo-simple-select-label">
          {field.fieldTitle}
        </InputLabel>
        <Select
          id={field.fieldName}
          key={field.fieldName}
          label={field.fieldTitle}
          name={field.fieldName}
          value={startVal()}
          defaultValue={defaultValue}
          disabled={readOnly}
          size={height}
          onChange={(e) => {
            setError(false);
            setFormData({
              ...formData,
              [field.fieldName]:
                e.target.value === defaultValue ? "" : e.target.value,
            });
          }}
          aria-label="select-dropdown"
        >
          {options.map((option) => {
            if (typeof option === "string") {
              return (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              );
            }
            return (
              <MenuItem key={option.title} value={option.value}>
                {option.title}
              </MenuItem>
            );
          })}
        </Select>
        {error && (
          <FormHelperText>
            {field.required ? "Required" : field.errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}

FormDropdown.propTypes = {
  setFormData: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired, //eslint-disable-line
  formData: PropTypes.object.isRequired, //eslint-disable-line
  readOnly: PropTypes.bool.isRequired,
  size: PropTypes.number,
  height: PropTypes.string,
};

FormDropdown.defaultProps = {
  size: 6,
  height: "normal",
};
