import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

// This component is to abstract buttons that redirects and have an onClick function
export default function LinkButton(props) {
  const { title, link, className, onClick, style } = props;

  // Goes through combination of linkbuttons that have an onclick function and/or particular styles
  if (onClick !== undefined && style) {
    return (
      <Link
        to={link}
        style={{ textDecoration: "none", color: "white" }}
        aria-label={title}
      >
        <Button
          key={title}
          type="button"
          onClick={onClick}
          className={className}
          sx={{
            my: 2,
            display: { sm: "none", md: "flex" },
          }}
        >
          {title}
        </Button>
      </Link>
    );
  }

  if (onClick !== undefined && !style) {
    return (
      <Link
        to={link}
        style={{ textDecoration: "none", color: "white" }}
        aria-label={title}
      >
        <Button
          key={title}
          type="button"
          onClick={onClick}
          className={className}
        >
          {title}
        </Button>
      </Link>
    );
  }

  if (onClick === undefined && style) {
    return (
      <Link
        to={link}
        style={{ textDecoration: "none", color: "white" }}
        aria-label={title}
      >
        <Button
          key={title}
          type="button"
          className={className}
          sx={{
            my: 2,
            display: { sm: "none", md: "flex" },
          }}
        >
          {title}
        </Button>
      </Link>
    );
  }

  if (onClick === undefined && !style) {
    return (
      <Link
        to={link}
        style={{ textDecoration: "none", color: "white" }}
        aria-label={title}
      >
        <Button key={title} type="button" className={className}>
          {title}
        </Button>
      </Link>
    );
  }
}

LinkButton.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  style: PropTypes.bool,
  onClick: PropTypes.func,
};

LinkButton.defaultProps = {
  onClick: undefined,
  style: false,
};
