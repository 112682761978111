import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes, { bool } from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import SearchResultRow from "../SearchResultRow";
import searchUtils from "../../utils/searchUtils";
import { setPaginationState } from "../../features/paginationState/paginationState";

export default function SearchResults(props) {
  const {
    invoices,
    index,
    setIndex,
    endResultIndex,
    admin,
    exportData,
    approveAll,
    errors,
    setErrors,
    setLoading,
    invoiceState,
    setInvoiceState,
  } = props;
  const [open, setOpen] = useState(false);
  const columnNames = [
    ...(admin ? ["CSC"] : []),
    "Invoice Type",
    "Service Type",
    "Invoice Date",
    "Invoice No.",
    "CAD Job No.",
    "Registration",
    "Breakdown Date",
    "Breakdown Location",
    "Total Value",
    ...(admin ? ["Potential Duplicate"] : []),
    "Status Changed",
    "Status",
    "Actions",
  ];
  const dispatch = useDispatch();
  const filters = useSelector(
    (state) => state.paginationState.value.filterOptions
  );

  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will approve all submitted invoices matching the filter
            options.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            autoFocus
          >
            Close
          </Button>
          <Button
            onClick={() => {
              approveAll();
              setOpen(false);
            }}
          >
            Approve All
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper} sx={{ mt: "2vh" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columnNames.map((columnName) => {
                return (
                  <TableCell key={`${columnName}search`}>
                    {columnName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {invoices.map((invoice) => (
              <SearchResultRow
                key={`invoice${invoice.invoiceId}`}
                invoice={invoice}
                admin={admin}
                errors={errors}
                setErrors={setErrors}
                setLoading={setLoading}
                invoiceState={invoiceState}
                setInvoiceState={setInvoiceState}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          mt: "2vh",
          display: "Flex",
          width: "600px",
          gap: "20px",
        }}
      >
        {admin && (
          <Button
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
          >
            Approve All
          </Button>
        )}
        <Button variant="contained" onClick={exportData}>
          Export
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            setIndex(Math.max(index - searchUtils.invoicesPerPage, 0));
            dispatch(
              setPaginationState({
                filterOptions: filters,
                index: Math.max(index - searchUtils.invoicesPerPage, 0),
              })
            );
          }}
          disabled={index === 0}
        >
          Prev
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            setIndex(index + searchUtils.invoicesPerPage);
            dispatch(
              setPaginationState({
                filterOptions: filters,
                index: index + searchUtils.invoicesPerPage,
              })
            );
          }}
          disabled={index + searchUtils.invoicesPerPage >= endResultIndex - 2}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

SearchResults.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired, //eslint-disable-line
  index: PropTypes.number.isRequired,
  endResultIndex: PropTypes.number.isRequired,
  setIndex: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object).isRequired, //eslint-disable-line
  setErrors: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  invoiceState: bool.isRequired,
  setInvoiceState: PropTypes.func.isRequired,
  exportData: PropTypes.func,
  approveAll: PropTypes.func,
  admin: bool,
};

SearchResults.defaultProps = {
  admin: false,
  exportData: () => {},
  approveAll: () => {},
};
